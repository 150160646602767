import { Component } from "react";
import { auth } from "../Components/Firebase";
import Api from "../Components/Api";
import { Button, Card, Form, InputGroup } from "react-bootstrap";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import AdminPrizeSubForm from "./AdminPrizeSubForm";
import { debounce } from "@mui/material";
import moment from "moment";

export default class AdminGameForm extends Component {
  constructor(props) {
    super(props);
    this.isCreate = !props.game.gameId;
    this.state = {
      validated: false,
      imageUploadError: false,
      saving: false,
      saveError: false,
      game: {
        ...props.game,
        customUI: { ...props.game.customUI },
        options: { ...props.game.options },
        prizes: props.game.prizes.map((prize) => {
          return {
            ...prize,
            images: [...prize.images],
          };
        }),
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        value = target.files[0];
        break;
      default:
        value = target.value;
    }
    let name = target.id;
    let attr;
    if (name.startsWith("customUI")) {
      [name, attr] = name.split(".");
      value = { ...this.state.game.customUI, [attr]: value };
    }
    const updates = { [name]: value };
    if (name === "startAt" && this.state.game.buyUntil === null) {
      updates.buyUntil = value.subtract(1, "second");
    }

    this.setState(
      {
        game: {
          ...this.state.game,
          ...updates,
        },
      },
      () => {
        console.log(this.state);
      }
    );
  }

  async handleSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    const form = event.currentTarget;
    this.setState({
      validated: true,
      saveError: false,
    });
    if (!form.checkValidity()) {
      return;
    }
    this.setState({ saving: true });

    const data = {
      ...this.state.game,
      customUI: {
        ...this.state.game.customUI,
      },
      options: {
        ...this.state.game.options,
        gameId: this.state.game.gameId,
      },
    };

    let method, url;
    if (this.isCreate) {
      method = "post";
      url = "/api/games";
    } else {
      method = "patch";
      url = `/api/games/${data.gameId}`;
    }

    const currentUser = auth.currentUser;
    const config = {
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: currentUser.accessToken,
        "Content-Type": "application/json",
      },
    };
    Api.request(config)
      .then(() => {
        this.setState({
          game: { ...data },
          saving: false,
        });
        if (this.props.onSubmit) {
          this.props.onSubmit(data);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          saveError: true,
          saving: false,
        });
      });
  }

  addPrize() {
    this.setState({
      game: {
        ...this.state.game,
        prizes: [
          ...this.state.game.prizes,
          {
            images: [],
            prizeId: "",
            gift: "",
            brand: "",
            maxPrice: 100,
            minPrice: 1,
            name: "",
            count: 1,
            allowUpdate: false,
            type: "",
            description: "",
            model: "",
          },
        ],
      },
    });
  }

  updatePrize(prize, index) {
    this.state.game.prizes.splice(index, 1, prize);
    this.setState({
      game: {
        ...this.state.game,
        prizes: this.state.game.prizes,
      },
      imageUploadError: this.state.game.prizes.some((p) => !p.images.length),
    });
  }

  renderDatePicker(field, label) {
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Datetime
          displayTimeZone={"Asia/Kolkata"}
          value={moment(this.state.game[field])}
          onChange={(newDate) => {
            if (typeof newDate !== "string") {
              this.setState({
                game: {
                  ...this.state.game,
                  [field]: newDate,
                },
              });
            }
          }}
          inputProps={{ required: true }}
          renderInput={(p, _o, _c) => (
            <>
              <Form.Control {...p}></Form.Control>
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </>
          )}
        ></Datetime>
      </Form.Group>
    );
  }

  render() {
    return (
      <Card className="cardcampaign">
        <Card.Body>
          <Form
            noValidate
            validated={this.state.validated}
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Form.Group controlId="gameId">
              <Form.Label>Game ID</Form.Label>
              <Form.Control
                type="text"
                value={this.state.game.gameId}
                disabled={!this.isCreate}
                onChange={this.handleInputChange}
                required
              />
              <Form.Text>
                This will be used to uniquely identify the game and as part of
                the URL
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="campaignId">
              <Form.Label>Campaign ID</Form.Label>
              <Form.Control
                type="text"
                value={this.state.game.campaignId}
                disabled={true}
                onChange={this.handleInputChange}
                required
              />
              <Form.Text>
                This identifies which campaign this game belongs to
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="customUI.title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={this.state.game.customUI.title}
                onChange={this.handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <label>Price drops</label>
            <InputGroup
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}
            >
              <Form.Group controlId="options.maxValue">
                <Form.Label>From</Form.Label>
                <InputGroup>
                  <InputGroup.Text>₹</InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={this.state.game.options.maxValue}
                    onChange={this.handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="options.minValue">
                <Form.Label>To</Form.Label>
                <InputGroup>
                  <InputGroup.Text>₹</InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={this.state.game.options.minValue}
                    onChange={this.handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="options.duration">
                <Form.Label>In</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    value={this.state.game.options.duration}
                    onChange={this.handleInputChange}
                  />
                  <InputGroup.Text>seconds</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </InputGroup>
            {this.renderDatePicker("startAt", "Game Start At")}
            <Form.Group controlId="isFreeGame">
              <Form.Check
                checked={this.state.game.isFreeGame}
                onChange={this.handleInputChange}
                inline={true}
                label="This game should be free"
              />
            </Form.Group>
            {!this.state.game.isFreeGame && (
              <>
                <Form.Group controlId="isEligibilityAtCampaignLevel">
                  <Form.Check
                    checked={this.state.game.isEligibilityAtCampaignLevel}
                    onChange={this.handleInputChange}
                    inline={true}
                    label="Eligibility is at Campaign Level"
                  />
                </Form.Group>
                <Form.Group controlId="isCouponsEnabled">
                  <Form.Check
                    checked={this.state.game.isCouponsEnabled}
                    inline={true}
                    readOnly={true}
                    disabled={true}
                    label="Coupons are enabled"
                  />
                </Form.Group>
                {this.renderDatePicker("buyUntil", "Allow buying coupon until")}
                <Form.Group controlId="buyAtPrice">
                  <Form.Label>Entry Fee</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>₹</InputGroup.Text>
                    <Form.Control
                      type="number"
                      value={this.state.game.buyAtPrice}
                      required
                      onChange={this.handleInputChange}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </>
            )}
            <InputGroup
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Form.Group controlId="customUI.background">
                <Form.Label>Background</Form.Label>
                <Form.Control
                  type="color"
                  value={this.state.game.customUI.background}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="customUI.primary">
                <Form.Label>Button</Form.Label>
                <Form.Control
                  type="color"
                  value={this.state.game.customUI.primary}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="customUI.secondary">
                <Form.Label>Text</Form.Label>
                <Form.Control
                  type="color"
                  value={this.state.game.customUI.secondary}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            </InputGroup>
            <hr />
            <h3>
              Prizes{" "}
              <Button
                style={{ float: "right" }}
                onClick={() => this.addPrize()}
              >
                Add Prize
              </Button>
            </h3>
            {this.state.game.prizes.map((prize, index) => (
              <AdminPrizeSubForm
                prize={prize}
                onChange={debounce((p) => this.updatePrize(p, index), 500)}
                key={index}
              ></AdminPrizeSubForm>
            ))}
            {this.state.imageUploadError && (
              <div
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: ".875em",
                  color: "#fd7e14",
                }}
              >
                Please wait while images finish uploading.
              </div>
            )}
            {this.state.saveError && (
              <div
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: ".875em",
                  color: "#dc3545",
                }}
              >
                Unable to save game. Please try again.
              </div>
            )}
            <Button
              className="BuyButton"
              type="submit"
              disabled={this.state.saving}
            >
              Submit
            </Button>
          </Form>
          {this.state.saving && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "-5rem",
                left: "-2rem",
                zIndex: 10,
                width: "calc(100% + 4rem)",
                height: "calc(100% + 8rem)",
                background: "#000000B8",
                color: "#FFFFFF",
              }}
            >
              <h3>Saving</h3>
              <p>This should take a few seconds</p>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }
}
