import React from "react";
import { Button, Card } from "react-bootstrap";
import "../index.css";
import moment from "moment";

function toMoment(timestamp) {
  switch (typeof timestamp) {
    case "number":
      return moment.unix(timestamp);
    case "object":
      try {
        return moment(timestamp.toDate());
      } catch {
        return moment(timestamp);
      }
    default:
      return moment(timestamp);
  }
}

const Cards = (props) => {
  let { data, onpress, canEdit, canDelete, onEdit, onDelete, markEnded } =
    props;
  let { endTimestamp, customUI, startTimestamp } = data || {};
  let {
    title,
    promoImageURL,
    prizesWorth,
    prizeDescription,
    primary,
    secondary,
    background,
  } = customUI || {};
  const buttonBg = primary === "DEFAULT" ? "" : primary;
  const textcolor = secondary === "DEFAULT" ? "" : secondary;
  startTimestamp = toMoment(startTimestamp);
  endTimestamp = toMoment(endTimestamp);
  const hasEnded = endTimestamp.isSameOrBefore(moment());
  const dateString2 = endTimestamp.format("DD MMM h:mm a");
  const dateString = startTimestamp.format("DD MMM h:mm a");
  return (
    <Card
      className="cardcampaign"
      onClick={onpress}
      style={
        background === "DEFAULT"
          ? { background: "rgba(0, 0, 0,0.3)" }
          : background && background.length === 1
          ? { background: background && background[0] }
          : {
              background: `linear-gradient(${background && background[0]},${
                background && background[1]
              })`,
            }
      }
    >
      <Card.Header className="d-flex justify-content-between">
        {canEdit && onEdit && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onEdit(data);
            }}
          >
            Edit
          </Button>
        )}
        {canDelete && onDelete && (
          <Button
            className="btn-danger"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(data);
            }}
          >
            Delete
          </Button>
        )}
      </Card.Header>
      <Card.Img
        variant="top"
        src={promoImageURL}
        style={{ height: "12rem", objectFit: "contain", marginTop: 20 }}
      />
      <Card.Body>
        <Card.Title className="whitetext" style={{ color: textcolor }}>
          {title}
        </Card.Title>

        <Card.Subtitle className="mb-2 whitetext2" style={{ color: textcolor }}>
          {prizeDescription}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 whitetext2" style={{ color: textcolor }}>
          Active from {dateString} to {dateString2}
        </Card.Subtitle>

        {markEnded && hasEnded ? (
          <Button
            className="BuyButton"
            style={{ background: buttonBg, color: textcolor }}
          >
            Already Ended
          </Button>
        ) : (
          <Button
            className="BuyButton bouncy1"
            style={{ background: buttonBg, color: textcolor }}
          >
            Prize Worth {"\u20B9"}
            {prizesWorth}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default Cards;
