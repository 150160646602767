import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import Loader from "../Components/Loader";
import { auth } from "../Components/Firebase";
import { gethistorydata, sendpurchase } from "../Store/action/auth";
import Prizes from "../Components/Prizes";
import { useNavigate, useParams } from "react-router-dom";
import { css } from "@emotion/css";
import moment from "moment";
import QRPaymentModal from "../Components/QRPaymentModal";

const Purchase = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentUser = auth.currentUser;
  const [loader, setLoader] = useState(true);
  const [buy, setbuy] = useState(true);
  const [showStart, setShow] = useState(false);
  const [shouldShowQRCode, setShouldShowQRCode] = useState(false);
  useEffect(() => {
    props.sendpurchase(
      {
        id: params.id,
        accessToken: currentUser.accessToken,
        userid: currentUser.uid,
      },
      (err) => {
        if (err) {
          setbuy(err.exits);
          setLoader(false);
        }
      }
    );
  }, []);
  let { purchase } = props || {};
  let { customUI, buyAtPrice, buyUntil, prizes, serverTimestamp } =
    purchase || {};
  let { title, description } = customUI || {};
  const { primary, secondary, background } = customUI || {};
  const buttonBg = primary === "DEFAULT" ? "" : primary;
  const textcolor = secondary === "DEFAULT" ? "" : secondary;
  const bgColor = background === "DEFAULT" ? "" : background;

  const onPaymentConfirmed = () => {
    setShouldShowQRCode(false);
    const currentUser = auth.currentUser;
    props.gethistorydata(currentUser.uid, (err) => {
      if (err.pass) {
        navigate(`/game/${params.id}`);
      }
    });
  };

  const canBuyEntry =
    buyUntil &&
    moment.unix(buyUntil._seconds * 1000).isAfter(moment.unix(serverTimestamp));
  return (
    <Container className="container1   ">
      {loader && <Loader />}
      {!loader && (
        <Container
          fluid="lg"
          className="mt50 mb50 buycard"
          style={{ background: bgColor }}
        >
          <Modal show={showStart} centered backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>Purchased successfull.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                className={"BuyButton"}
                type="submit"
                onClick={() => {
                  setShow(false);
                }}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>
          {shouldShowQRCode && (
            <QRPaymentModal
              game={props.purchase}
              onPaymentConfirmed={() => onPaymentConfirmed()}
              onPaymentCancelled={() => setShouldShowQRCode(false)}
            ></QRPaymentModal>
          )}
          <Row className="justify-content-md-center mt50 mb50 ">
            <Col lg="4">
              <Carousel style={{ width: 320, height: 320 }}>
                {prizes.map((item) => (
                  <Carousel.Item key={item.prizeId}>
                    {/* <div  className="textwhite">
                 <p style={{ textAlign: "center" }}>{item.count} {item.brand}</p>
                </div> 
                <img
                  style={{ width: 300, height: 350,}}
                  className="d-block prizes"
                  src={item.images&&item.images[0]}
                  alt="First slide"
                /> */}
                    <Prizes customUI={customUI} data={item} />
                  </Carousel.Item>
                ))}
              </Carousel>

              {/* <Image src={promoImageURL} style={{ width: 300, height: 300,position:'absolute',right:0 }} /> */}
            </Col>

            <Col className="textwhite" lg="5" xs="10 ">
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: textcolor,
                }}
              >
                {title}
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: textcolor,
                  // background:
                  //   "linear-gradient(rgba(255, 0, 0, 0.4),rgba(0, 0, 0,0.3))",
                  // color: "#fff",
                  // boxShadow: "0 0 10px 10px rgba(16, 20, 15,0.8) inset",
                  // padding: "5px",
                  // textAlign: "center",
                }}
              >
                {description}
              </p>
              <p
                className="price"
                style={{
                  textAlign: "center",
                  color: textcolor,
                  // boxShadow: "0 0 10px 10px rgba(16, 20, 15,0.8) inset",
                }}
              >
                Coupon Price
                <span
                  style={{
                    fontSize: "36px",
                    fontWeight: "bold",
                    padding: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {" "}
                  {"\u20B9"}
                  {buyAtPrice}
                </span>
              </p>

              <div>
                {!buy && canBuyEntry && (
                  <Button
                    className={`Button2
                     ${
                       primary === "DEFAULT"
                         ? ""
                         : css`
                             background: ${buttonBg};
                             color: ${textcolor};
                             &:hover {
                               background: ${textcolor};
                               color: ${buttonBg};
                             }
                           `
                     }`}
                    onClick={() => setShouldShowQRCode(true)}
                  >
                    Buy Now
                  </Button>
                )}
                {buy && (
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: textcolor,
                      }}
                    >
                      {"You have purchased coupon for this game"}
                    </p>
                    <Button
                      className={`BuyButton
                     ${
                       primary === "DEFAULT"
                         ? ""
                         : css`
                             background: ${buttonBg};
                             color: ${textcolor};
                             &:hover {
                               background: ${textcolor};
                               color: ${buttonBg};
                             }
                           `
                     }`}
                      onClick={() => navigate(`/game/${params.id}`)}
                    >
                      Goto Game Page
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  purchase: state.authReducer.purchase,
});
const mapDispatchToProps = (dispatch) => ({
  sendpurchase: (data, callback) => dispatch(sendpurchase(data, callback)),
  gethistorydata: (data, callback) => dispatch(gethistorydata(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
