import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";
import GameWidget from "./GameWidget";
import GameLog from "./GameLog";
import FeedbackModal from "./feedbackModal";
import Api from "./Api";
import moment from "moment";
import { auth } from "./Firebase";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

const ONE_SECOND = 1000;
class Game extends Component {
  mounted = false;

  constructor(props) {
    super(props);
    const hasPurchasedCoupon = this.props.eligible.some(
      (game) => game.gameId === this.props.gameId
    );
    this.state = {
      ...this.props.gameState,
      startAt: null, // for manual start such as in offline demo
      showResults: false,
      userPrice: null,
      showFeedbackModal: false,
      gameStateLoaded: false,
      hasPurchasedCoupon,
    };
    this.fetchGameState();

    this.onGameEnd = this.onGameEnd.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  fetchGameState() {
    if (this.props.gameState && Object.entries(this.props.gameState).length) {
      console.info("Game state provided. Skipping fetching from server.");
      this.setGameStateLoaded(true);
      return;
    }

    this.setGameStateLoaded(false);

    const currentUser = auth.currentUser;
    Api.get(`/api/getGameDetails?gameId=${this.props.gameId}`, {
      headers: {
        Authorization: currentUser.accessToken,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const data = res.data.data;
      const gameStartTime = moment(
        new Date(data.startAt._seconds * ONE_SECOND)
      );
      const gameState = {
        gameTime: data.options.duration,
        gamePrice: data.options.maxValue,
        dropsPerSecond: 100,
        countdownTime: 10,
        minPrice: data.options.minValue,
        userPhone: currentUser.phoneNumber,
        gameId: data.gameId,
        imageUrl: data.imageUrl,
        videoUrl: data.videoUrl,
        description: data.description,
        detailsUrl: data.detailsUrl,
        startAt: gameStartTime,
        gameStateLoaded: true,
        isFreeGame: data.isFreeGame,
        buyAtPrice: data.buyAtPrice,
        buyUntil: data.buyUntil
          ? moment(data.buyUntil._seconds * ONE_SECOND)
          : null,
        status: data.status,
      };

      if (gameStartTime.isBefore(moment().subtract(1, "minute"))) {
        gameState.title = "Game Over";
        gameState.subTitle = `Last Game Started at ${gameStartTime.format(
          "MMM DD, YYYY, hh:mm a"
        )}`;
        gameState.showResults = true;
      } else {
        gameState.title = data.title;
        gameState.subTitle = `Next Game Starts at ${gameStartTime.format(
          "MMM DD, YYYY, hh:mm a"
        )}`;
        gameState.showResults = false;
      }

      if (this.mounted) {
        this.setState({ ...gameState });
      } else {
        // needed for hot reload
        this.state = {
          ...this.state,
          ...gameState,
        };
      }
      console.log("State updated with fetched game details");
    });
  }

  setGameStateLoaded(loaded) {
    if (this.mounted) {
      this.setState({ gameStateLoaded: loaded });
    } else {
      //  called indirectly from constructor
      this.state.gameStateLoaded = loaded;
    }
  }

  playSlideSound = () => {
    const audio = new Audio(require("../resources/audio/ion/SlideDown.mp3"));
    audio.play();
  };

  renderVideo = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `<video loop muted autoplay playsinline class="table-fixed"><source src="${this.state.videoUrl}"><img src="${this.state.imageUrl}" /></video>`,
        }}
      ></div>
    );
  };

  onGameEnd(userSelection) {
    this.playSlideSound();
    if (this.state.startAt?.add(1, "minute").isBefore(moment())) {
      this.setState({
        title: "Game Over",
        subTitle: `Last Game Started at ${this.state.startAt.format(
          "MMM DD, YYYY, hh:mm a"
        )}`,
      });
    }

    this.setState({
      showResults: true,
      userPrice: userSelection?.price,
    });
  }

  render() {
    return (
      <Card className="text-center mt50 cont">
        <Card.Body>
          <Card.Title>
            <h1 className="h1">{this.state.title}</h1>
          </Card.Title>
          <Card.Subtitle>
            <h4 className="h4 whitetext">{this.state.subTitle}</h4>
          </Card.Subtitle>
          {this.state.videoUrl && this.renderVideo()}
          {!this.state.videoUrl && this.state.imageUrl && (
            <img
              className="table-fixed"
              src={this.state.imageUrl}
              alt={this.state.title}
            ></img>
          )}
          <div className="whitetext">
            {this.state.description && (
              <p className="CFLeft FontSize1x">{this.state.description}</p>
            )}
            {this.state.detailsUrl && (
              <p className="CFRight FontSize0_75x">
                <a
                  className="whitetext"
                  href={this.state.detailsUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Details
                </a>
              </p>
            )}
          </div>
          {!this.state.showResults && !this.state.gameStateLoaded && (
            <p className="whitetext h4">Loading...</p>
          )}
          {!this.state.showResults && this.state.gameStateLoaded && (
            <>
              <GameWidget
                gameId={this.props.gameId}
                gamePrice={this.state.gamePrice}
                minPrice={this.state.minPrice}
                gameTime={this.state.gameTime}
                countdownTime={this.state.countdownTime}
                dropsPerSecond={this.state.dropsPerSecond}
                startAt={this.state.startAt}
                onGameEnd={this.onGameEnd}
                canPlay={this.state.isFreeGame || this.state.hasPurchasedCoupon}
              ></GameWidget>
              {!this.state.isFreeGame && !this.state.hasPurchasedCoupon && (
                <NavLink
                  to={`/purchase/${this.props.gameId}`}
                  className="BuyButton btn btn-primary"
                >
                  Buy Coupon To Play
                </NavLink>
              )}
            </>
          )}
          {this.state.showResults && (
            <div>
              <GameLog
                gameId={this.props.gameId}
                userPrice={this.state.userPrice}
                userPhone={this.state.userPhone}
                disableAcceptingPrize={!this.props.onPrizeAccepted}
                onPrizeAccepted={this.props.onPrizeAccepted}
              ></GameLog>
              {this.props.includePlayAgainButton && (
                <Button
                  className="Button2"
                  onClick={() => {
                    this.playSlideSound();
                    this.fetchGameState();
                    this.setState({
                      showResults: false,
                      userPrice: null,
                    });
                  }}
                >
                  Play Again
                </Button>
              )}
              {this.props.includeFeedbackButton && (
                <Button
                  className="Button2"
                  onClick={() => this.setState({ showFeedbackModal: true })}
                >
                  Feedback
                </Button>
              )}
            </div>
          )}
          {this.state.showFeedbackModal && (
            <FeedbackModal
              gameId={this.props.gameId}
              onClose={() => this.setState({ showFeedbackModal: false })}
            ></FeedbackModal>
          )}
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  eligible: state.authReducer.eligible,
});
export default connect(mapStateToProps)(Game);
