import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Page404 = () => {
  return (
    <Container className="container1 containerheight">
      <Row>
        <Col lg="2">
          {" "}
          <a href="/campaigns">Go to Home</a>
        </Col>
        <Col lg="8">
          <img
            style={{ width: "100%" }}
            src={require("../resources/images/404.png")}
          ></img>
        </Col>
        <Col lg="2"></Col>
      </Row>
    </Container>
  );
};

export default Page404;
