import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "react-component-countdown-timer/lib/styles.css";
import RealtimeClock from "../Components/RealtimeClock";
import Loader from "../Components/Loader";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "./verticaltimeline.css";
import Game from "../Components/Game";

class LiveDemoGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      showStart: true,
    };
  }

  render() {
    let { servertime, showStart, loader } = this.state;
    return (
      <Container fluid className="gameBackgrund">
        {loader && <Loader />}
        {!loader && (
          <div>
            <Modal show={showStart} centered backdrop="static" keyboard={false}>
              <Modal.Header>
                <Modal.Title>
                  Practice game no prizes will be awarded.
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button
                  className={"BuyButton"}
                  type="submit"
                  onClick={() => {
                    this.setState({
                      showStart: false,
                      loader: false,
                    });
                  }}
                >
                  GO TO GAME
                </Button>
              </Modal.Body>
            </Modal>

            <RealtimeClock time={servertime} />
            <Row className="justify-content-md-center ">
              <Col lg="4">
                <Game
                  gameId={"live-demo-game"}
                  includePlayAgainButton={true}
                ></Game>
              </Col>

              <Col lg="6" className="mt50 mb50">
                <VerticalTimeline layout={"1-column"}>
                  <VerticalTimelineElement
                    position={"left"}
                    iconStyle={{
                      background: "rgb(16, 204, 82)",
                      color: "#fff",
                    }}
                    contentStyle={{
                      background:
                        "linear-gradient(rgba(16, 204, 82,0.3),rgba(0, 0, 0,0.3))",
                      color: "#fff",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      <strong>
                        Once Game Starts, game will run for 60 seconds
                      </strong>
                    </p>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    position={"left"}
                    className="vertical-timeline-element--work"
                    iconStyle={{
                      background: "rgb(33, 150, 243)",
                      color: "#fff",
                    }}
                    contentStyle={{
                      background:
                        "linear-gradient(rgba(33, 150, 243,0.3),rgba(0, 0, 0,0.3))",
                      color: "#fff",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      <strong>
                        Price will drop from MRP to Rs 1 or a minimum Price.
                      </strong>
                    </p>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    position={"left"}
                    className="vertical-timeline-element--work"
                    iconStyle={{
                      background: "rgb(233, 30, 99)",
                      color: "#fff",
                    }}
                    contentStyle={{
                      background:
                        "linear-gradient(rgba(233, 30, 99,0.3),rgba(0, 0, 0,0.3))",
                      color: "#fff",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      <strong>
                        Select the Price you are willing to pay by press
                        play2win button
                      </strong>
                    </p>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    iconStyle={{
                      background: "rgb(33, 150, 243)",
                      color: "#fff",
                    }}
                    contentStyle={{
                      background:
                        "linear-gradient(rgba(33, 150, 243,0.3),rgba(0, 0, 0,0.3))",
                      color: "#fff",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      <strong>
                        At end of 60 seconds, the game is over and you can see
                        all the people who played and their respective selected
                        Prices.
                      </strong>
                    </p>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    position={"left"}
                    className="vertical-timeline-element--education"
                    iconStyle={{
                      background: "rgb(16, 204, 82)",
                      color: "#fff",
                    }}
                    contentStyle={{
                      background:
                        "linear-gradient(rgba(16, 204, 82,0.3),rgba(0, 0, 0,0.3))",
                      color: "#fff",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      <strong>
                        As this game is for practice no prizes will be awarded.
                      </strong>
                    </p>
                  </VerticalTimelineElement>
                </VerticalTimeline>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}

export default LiveDemoGame;
