import React from "react";
import { Navbar, Nav, Container, Col, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import logosmall from "../resources/icons/logo-small.png";

const Footer = () => {
  return (
    <Container
      fluid
      style={{
        width: "100%",
        background: "black",
        height: "auto",
        position: "relative",
        bottom: 0,
      }}
    >
      <Row>
        <Col
          style={{
            paddingTop: 30,
            paddingBottom: 20,
            margin: "auto",
            color: "white",
          }}
          lg="4"
        >
          <a
            href="https://www.facebook.com/Play2win-106658914576943/"
            target="blank"
          >
            <img
              alt="facebook"
              src={require("../resources/icons/fb.png")}
              style={{ marginLeft: 20, width: 30, height: 30 }}
            />
          </a>
          <a
            href="https://instagram.com/play.to.win567?igshid=171g2xk8t33x5"
            target="blank"
          >
            <img
              alt="instagram"
              src={require("../resources/icons/instagram.png")}
              style={{ marginLeft: 20, width: 35, height: 35 }}
            />
          </a>
        </Col>

        <Col
          style={{
            paddingTop: 30,
            paddingBottom: 20,
            margin: "auto",
            color: "white",
          }}
        >
          &copy; Copyright-2020 Play2Win, Patent Pending Technology
        </Col>
        <Col
          lg="3"
          style={{
            marginLeft: 10,
            paddingTop: 30,
            paddingBottom: 20,
            margin: "auto",
            color: "white",
          }}
        >
          <Link className="whitetext" to="/termsandcondition">
            Terms & Conditons
          </Link>
          <br />
          <Link className="whitetext" to="/privacypolicy">
            {" "}
            Privacy Policy
          </Link>
          <br />
          <Link className="whitetext" to="/refundpolicy">
            {" "}
            Return & Refund Policy
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
