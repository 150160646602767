import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Component } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import { auth, database } from "./Firebase";
import { onValue, ref } from "firebase/database";
import Api from "./Api";

const ONE_SECOND = 1000; // 1000 ms in a second
export default class GameWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameOngoing: false,
      countdownOngoing: false,
      timerPrefix: "GAME TIME",
      timerSuffix: "Seconds",
      timerDuration: this.props.gameTime,
      timerInitialRemainingTime: this.props.gameTime,
      timerKey: null, // used to differentiate timers
      timerColors: ["#FFFFFF", "#F7B801", "#A30000"],
      timerColorTimes: [],
      tickAudioModule: null,
      userSelection: null,
      currentPrice: this.props.gamePrice,
      startAt: this.props.startAt,
      serverTimestamp: null, // this is set via the data in the database
    };
    this.waitForStartTimer = null;
    // noop if not starting automatically...
    this.dbUnsubscribe = () => {};

    if (this.state.startAt) {
      this.waitForStartTimer = setInterval(
        () => this.updateTimeToStart(),
        ONE_SECOND
      );
      const dbReference =
        this.props.gameId === "live-demo-game"
          ? ref(database, this.props.gameId)
          : ref(database, `live-games/${this.props.gameId}`);
      this.dbUnsubscribe = onValue(dbReference, (snapshot) => {
        const val = snapshot.val();
        if (!val) {
          return;
        }
        this.setState({
          serverTimestamp: val.serverTimestamp,
        });
        if (val.isGameRunning) {
          if (this.state.gameOngoing) {
            this.setState({
              currentPrice: parseInt(val.currentPrice),
              timerInitialRemainingTime: val.countDownValue,
            });
          } else {
            // todo: see if this can become startGame
            const diff = moment().diff(this.state.startAt, "seconds");
            const alreadyStarted = diff < 0;
            const timeRemaining = alreadyStarted
              ? this.props.gameTime
              : this.props.gameTime - diff;
            this.setState({
              gameOngoing: true,
              countdownOngoing: false,
              timerDuration: this.props.gameTime,
              timerInitialRemainingTime: timeRemaining,
              timerKey: "gameTimer",
              timerPrefix: "Game Ends In",
              timerColorTimes: [
                this.props.gameTime,
                this.props.gameTime / 2,
                0,
              ],
              tickAudioModule: require("../resources/audio/ion/water_droplet.mp3"),
            });
          }
        } else if (val.isGameOver && this.state.gameOngoing) {
          this.endGame();
        }
      });
    }
  }

  componentWillUnmount() {
    this.dbUnsubscribe();
  }

  startCountdown() {
    this.setState({
      countdownOngoing: true,
      timerDuration: this.props.countdownTime,
      timerInitialRemainingTime: this.props.countdownTime,
      timerKey: "countdown",
      timerPrefix: "Game Starts In",
      timerColorTimes: [
        this.props.countdownTime,
        this.props.countdownTime / 2,
        0,
      ],
      tickAudioModule: require("../resources/audio/ion/button_tiny.mp3"),
    });
  }

  updateTimeToStart() {
    const diff = this.state.startAt.diff(moment(), "seconds");
    if (diff <= 0) {
      clearInterval(this.waitForStartTimer);
      this.setState({
        timerPrefix: "GAME TIME",
        timerKey: "foo",
        timerDuration: this.props.gameTime,
        timerSuffix: "Seconds",
      });
      return;
    }
    this.setState({
      timerPrefix: "Game Starts In",
      timerKey: "foo",
      timerDuration: diff,
      timerInitialRemainingTime: diff,
      timerSuffix: "",
    });
  }

  startGame() {
    clearInterval(this.waitForStartTimer);
    this.setState({
      gameOngoing: true,
      countdownOngoing: false,
      timerDuration: this.props.gameTime,
      timerInitialRemainingTime: this.props.gameTime,
      timerKey: "gameTimer",
      timerPrefix: "Game Ends In",
      timerColorTimes: [this.props.gameTime, this.props.gameTime / 2, 0],
      tickAudioModule: require("../resources/audio/ion/water_droplet.mp3"),
    });
    const dropRate =
      this.props.gamePrice / (this.props.gameTime * this.props.dropsPerSecond);
    const dropInterval = ONE_SECOND / this.props.dropsPerSecond;
    this.priceTimer = setInterval(() => {
      let currentPrice = this.state.currentPrice - dropRate;
      if (Math.round(currentPrice) === 0) {
        currentPrice = this.props.minPrice;
      }
      this.setState({
        currentPrice: currentPrice,
      });
    }, dropInterval);
  }

  endGame() {
    this.setState({
      gameOngoing: false,
    });
    clearInterval(this.priceTimer);
    if (this.props.onGameEnd) {
      this.props.onGameEnd(this.state.userSelection);
    }
  }

  playTick = () => {
    if (this.state.tickAudioModule) {
      const audio = new Audio(this.state.tickAudioModule);
      audio.play();
    }
  };

  playClick = () => {
    const audio = new Audio(
      require("../resources/audio/ion/camera_flashing_2.mp3")
    );
    audio.play();
  };

  freezePrice = () => {
    const lockedPrice = this.state.currentPrice;
    this.setState({
      userSelection: {
        price: lockedPrice,
      },
    });
    if (this.props.gameId === "Demo game") {
      // the demo game uses a fake log so no need to post to the server.
      return;
    }

    const currentUser = auth.currentUser;
    let data = {
      userId: currentUser.uid,
      userName: currentUser.displayName,
      gameId: this.props.gameId,
      lockedPrice: lockedPrice,
      lockedTimestamp: this.state.serverTimestamp,
      email: currentUser.email,
      phoneNumber: currentUser.phoneNumber,
    };

    Api.post(`/api/createGameLog`, data, {
      headers: {
        Authorization: currentUser.accessToken,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        this.setState({
          userSelection: null,
        });
      });
  };

  formatTime(numberOfSeconds) {
    if (this.state.timerSuffix) {
      //if there is a suffix we don't need the units
      return numberOfSeconds;
    }
    const hours = Math.floor(numberOfSeconds / 3600);
    const minutes = Math.floor((numberOfSeconds % 3600) / 60);
    const seconds = numberOfSeconds % 60;
    if (hours) {
      return `${hours}h : ${minutes}m : ${seconds}s`;
    }
    return `${minutes}m : ${seconds}s`;
  }

  render = () => {
    return (
      <div>
        <div className="countdown">
          <CountdownCircleTimer
            isPlaying={this.state.gameOngoing || this.state.countdownOngoing}
            duration={this.state.timerDuration}
            initialRemainingTime={this.state.timerInitialRemainingTime}
            colors={this.state.timerColors}
            colorsTime={this.state.timerColorTimes}
            key={this.state.timerKey}
            onUpdate={(remainingTime) => {
              this.playTick();
            }}
            onComplete={() => {
              if (this.state.countdownOngoing) {
                this.startGame();
                return { shouldRepeat: false };
              }
              this.endGame();
              return { shouldRepeat: false };
            }}
            size={250}
          >
            {(timeProps) => (
              <div
                style={{ color: timeProps.color }}
                role="timer"
                aria-live="assertive"
              >
                <div className="textgame">{this.state.timerPrefix}</div>
                <div
                  className={
                    (this.state.timerSuffix ? "time-wrapper " : "h4") +
                    (this.state.gameOngoing ? "bouncy" : "")
                  }
                >
                  {this.formatTime(timeProps.remainingTime)}
                </div>
                <div className="textgame">{this.state.timerSuffix}</div>
              </div>
            )}
          </CountdownCircleTimer>
        </div>
        <p className="price">
          Price {`\u20B9`}
          {this.state.currentPrice}
        </p>
        {!this.state.countdownOngoing &&
          !this.state.userSelection &&
          this.props.canPlay && (
            <div id="p2wButton">
              <div className="buttonRiff" id="Riff01"></div>
              <div className="buttonRiff" id="Riff02"></div>
              <div className="buttonRiff" id="Riff03"></div>
              <button
                id="buttonDiv"
                disabled={!!this.state.userSelection}
                onClick={() => {
                  if (this.state.gameOngoing) {
                    this.playClick();
                    this.freezePrice();
                  } else if (!this.state.startAt) {
                    this.startCountdown();
                  }
                }}
              ></button>
            </div>
          )}
        {!!this.state.userSelection && (
          <Button disabled={true} className="playButton2">
            {`\u20B9`}
            {this.state.userSelection.price}
          </Button>
        )}
        {!this.state.startAt &&
          !this.state.countdownOngoing &&
          !this.state.gameOngoing && (
            <div id="reseToast">Click the button to start!</div>
          )}
      </div>
    );
  };
}
