import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { getFirebase } from "react-redux-firebase";
import { rootReducer } from "./reducer";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
const persistConfig = {
  key: "authType",
  storage: storage,
  whitelist: ["authReducer"], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk.withExtraArgument(getFirebase)];
const store = createStore(
  pReducer,
  {},
  compose(applyMiddleware(...middlewares))
);
let persistor = persistStore(store);
export { store, persistor };
