import React from "react";
import { Image } from "react-bootstrap";
import "../index.css";

const Loader = () => {
  return (
    <div className="loaderPage">
      <div className="loader">
        <div className="loaderWheel"></div>
        <Image
          className="loaderLogo"
          src={require("../resources/icons/logo-med.png")}
          alt="Play2Win-Logo"
        />
        <div className="loaderText">
          <div className="loaderChar load-L">L</div>
          <div className="loaderChar load-O">O</div>
          <div className="loaderChar load-A">A</div>
          <div className="loaderChar load-D">D</div>
          <div className="loaderChar load-I">I</div>
          <div className="loaderChar load-N">N</div>
          <div className="loaderChar load-G">G</div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
