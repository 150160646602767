import { Component } from "react";
import { Form, Stack } from "react-bootstrap";

import "react-datetime/css/react-datetime.css";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "./Firebase";

export default class AdminPrizeSubForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prize: {
        ...props.prize,
        images: [...props.prize.images],
      },
    };
    if (this.state.prize.allowUpdate === undefined) {
      this.state.prize.allowUpdate = false;
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async handleInputChange(event) {
    const target = event.target;
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        const promises = [];
        Array.from(target.files).forEach((file, index) => {
          if (typeof file === "string") {
            promises.push(Promise(() => file));
          } else {
            const ext = file.name.split(".").pop();
            const storageRef = ref(
              storage,
              `prizes/${this.state.prize.prizeId}/${index}.${ext}`
            );
            promises.push(
              uploadBytes(storageRef, file).then((snapshot) =>
                getDownloadURL(snapshot.ref)
              )
            );
          }
        });
        value = await Promise.all(promises);
        break;
      default:
        value = target.value;
    }
    let name = target.id;

    const prize = {
      ...this.state.prize,
      [name]: value,
    };
    this.setState({
      prize: prize,
    });
    if (this.props.onChange) {
      this.props.onChange(prize);
    }
  }
  render() {
    return (
      <Stack gap={3}>
        <Stack direction="horizontal" gap={3}>
          <Form.Group controlId="prizeId">
            <Form.Label>Prize ID</Form.Label>
            <Form.Control
              type="text"
              value={this.state.prize.prizeId}
              onChange={this.handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={this.state.prize.name}
              onChange={this.handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={this.state.prize.description}
              onChange={this.handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="brand">
            <Form.Label>Brand</Form.Label>
            <Form.Control
              type="text"
              value={this.state.prize.brand}
              onChange={this.handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
        </Stack>
        <Stack direction="horizontal" gap={3}>
          <Form.Group controlId="model">
            <Form.Label>Model</Form.Label>
            <Form.Control
              type="text"
              value={this.state.prize.model}
              onChange={this.handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="type">
            <Form.Label>Type</Form.Label>
            <Form.Control
              type="text"
              value={this.state.prize.type}
              onChange={this.handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="images">
            <Form.Label>Images</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              multiple
              onChange={this.handleInputChange}
            />
          </Form.Group>
        </Stack>
      </Stack>
    );
  }
}
