import React from "react";
import { Button, Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import { auth } from "../Components/Firebase";
import "react-component-countdown-timer/lib/styles.css";
import Loader from "../Components/Loader";
import Api from "../Components/Api";
import Prizes from "../Components/Prizes";
import RealtimeClock from "../Components/RealtimeClock";
import { withRouter } from "../Components/WithRouter";
import Game from "../Components/Game";

class Maingame extends React.Component {
  constructor(props) {
    super(props);
    this.audio = new Audio(require("../resources/audio/ion/button_tiny.mp3"));
    this.tick = new Audio(require("../resources/audio/ion/water_droplet.mp3"));
    this.slide = new Audio(require("../resources/audio/ion/SlideDown.mp3"));
    this.clicking = new Audio(
      require("../resources/audio/ion/camera_flashing_2.mp3")
    );
    this.gameId = this.props.router.params.id;
    this.state = {
      showemailmodal: false,
      loader: false,
      showStart: true,
      showPrizes: false,
      campaignId: "",
      prizes: [],
    };
  }

  componentDidMount() {}

  getData = () => {
    Api.get(`/api/getGameDetails?gameId=${this.gameId}`, {
      headers: {
        Authorization: auth.currentUser.accessToken,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const data = res.data.data;
      this.setState({
        // campaignId: data.campaignId,
        // gameId: data.gameId,
        prizes: data.prizes,
        customUI: data.customUI,
        loader: false,
      });
    });
  };

  acceptPrize = () => {
    let { prizes } = this.state;
    this.setState({ showPrizes: false });
    const currentUser = auth.currentUser;
    let data = {
      userId: currentUser.uid,
      prize: prizes && prizes[0],
      gameId: this.gameId,
    };

    Api.post(`/api/processPrizeAccepted`, data, {
      headers: {
        Authorization: auth.currentUser.accessToken,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({ showemailmodal: true });
      })
      .catch((err) => {
        this.setState({ showPrizes: true });
      });
  };

  componentWillUnmount() {
    clearInterval(this.state.x);
    clearInterval(this.state.game);
  }
  render() {
    let { customUI, prizes, loader, showPrizes, showStart, showemailmodal } =
      this.state;
    return (
      <Container fluid className="gameBackgrund">
        {loader && <Loader />}
        {!loader && (
          <div>
            <Modal
              show={showemailmodal}
              centered
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>
                  Check your email for details on your Prize
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button
                  className={"BuyButton"}
                  type="submit"
                  onClick={() => {
                    this.setState({
                      showemailmodal: false,
                    });
                  }}
                >
                  Close
                </Button>
              </Modal.Body>
            </Modal>
            <Modal show={showStart} centered backdrop="static" keyboard={false}>
              <Modal.Header>
                <Modal.Title>
                  Select the Price you are willing to pay once game start
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button
                  className={"BuyButton"}
                  type="submit"
                  onClick={() => {
                    this.setState({
                      showStart: false,
                      loader: true,
                    });
                    this.getData();
                  }}
                >
                  GO TO GAME
                </Button>
              </Modal.Body>
            </Modal>
            <Modal
              show={showPrizes}
              centered
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>
                  You have won an Assured Prize! If you choose to accept it, we
                  will contact you with the details. Do you accept it?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button
                  className={"Button2"}
                  type="submit"
                  onClick={() => {
                    this.acceptPrize();
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={"Button2"}
                  type="submit"
                  onClick={() => {
                    this.setState({ showPrizes: false });
                  }}
                >
                  NO
                </Button>
              </Modal.Body>
            </Modal>
            <RealtimeClock />
            <Row className="justify-content-md-center ">
              <Col lg="4">
                <Game
                  gameId={this.gameId}
                  onPrizeAccepted={this.acceptPrize}
                ></Game>
              </Col>

              <Col lg="4" className=" mt50 ">
                <p className="whitetext boldresult" style={{ width: 320 }}>
                  Win Different Prizes
                </p>
                <Carousel
                  style={{ width: 320, height: 320 }}
                  className="mt20 mb50"
                >
                  {prizes.map((item) => (
                    <Carousel.Item key={item.prizeId}>
                      <Prizes customUI={customUI} data={item} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}

export default withRouter(Maingame);
