import {
  SENDRESULT,
  FETCHACTIVEGAMES,
  SENDTOKEN,
  SENDTOPURHASE,
  ACTIVECAMPAIGNS,
  SENDCAMPAIGNS,
  STOREUSER,
  USERHISTORY,
} from "../action/actionType";
const initialState = {
  userlist: [],
  result: "",
  upcoming: [],
  purchase: {},
  activecampaigns: [],
  campaignsid: "",
  user: {},
  historys: [],
  eligible: [],
  sendvalid: false,
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case STOREUSER: {
      return { ...state, user: action.payload };
    }
    case SENDRESULT: {
      const { userlist, result } = action.payload;
      return { ...state, userlist, result };
    }
    case SENDTOKEN: {
      return { ...state, sendvalid: action.payload };
    }
    case FETCHACTIVEGAMES: {
      return { ...state, upcoming: action.payload };
    }
    case SENDTOPURHASE: {
      return { ...state, purchase: action.payload };
    }
    case ACTIVECAMPAIGNS: {
      return { ...state, activecampaigns: action.payload };
    }
    case SENDCAMPAIGNS: {
      return { ...state, campaignsid: action.payload };
    }
    case USERHISTORY: {
      const { historys, eligible } = action.payload;
      return { ...state, historys, eligible };
    }

    default:
      return state;
  }
}
