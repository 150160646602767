const otherUserPhones = [
  "9162637***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9766847***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265647***",
  "9163634***",
  "9465657***",
  "9765647***",
  "9755647***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9765684***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265647***",
  "9163647***",
  "9465657***",
  "9765647***",
  "9755647***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9765684***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265647***",
  "9163634***",
  "9465657***",
  "9765647***",
  "9755647***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9765684***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265627***",
  "9163637***",
  "9465659***",
  "9765647***",
  "9755647***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9765687***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265647***",
  "9163637***",
  "9465657***",
  "9765647***",
  "9755647***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9765847***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265647***",
  "9163747***",
  "9465657***",
  "9765647***",
  "9755647***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9765684***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265647***",
  "9163634***",
  "9465657***",
  "9765647***",
  "9755647***",
  "9263657***",
  "9162647***",
  "9565747***",
  "9869647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9765647***",
  "9768647***",
  "9761647***",
  "9745647***",
  "9565647***",
  "9765687***",
  "9765677***",
  "9765699***",
  "7765647***",
  "9766647***",
  "9745647***",
  "9265647***",
  "9163347***",
  "9465657***",
  "9765647***",
  "9755687***",
  "9755247***",
];
const otherUserPrices = [
  2790000, 2754000, 2713500, 2695500, 2645800, 2612000, 2458000, 2412500,
  2385200, 2253500, 96960, 96463, 96013, 95994, 95808, 95709, 95704, 95641,
  95461, 94769, 94752, 93546, 92746, 92176, 92164, 92146, 92143, 92106, 92103,
  91756, 91546, 91513, 91475, 91406, 90874, 90468, 90321, 90001, 89946, 89746,
  89674, 89570, 89565, 89469, 89465, 89204, 89144, 88874, 88461, 87940, 87490,
  86049, 8597, 85761, 85103, 84952, 84861, 84652, 81640, 80497, 78978, 78894,
  78748, 78654, 78349, 78295, 78204, 78189, 78164, 78104, 78094, 78012, 77798,
  75767, 75469, 75467, 75034, 74689, 74670, 73894, 73756, 73667, 73504, 73406,
  73094, 73009, 72891, 72764, 72640, 72250, 72154, 72089, 72067, 72000, 71974,
  71644, 71064, 70770, 70640, 69049, 68000, 67052, 63465, 63323, 63013, 62993,
  62971, 62460, 62019, 61592, 61576, 61574, 61450, 61304, 61204, 60873, 60647,
  60404, 59660, 59504, 59402, 59320, 58973, 58640, 58490, 58460, 56910, 56727,
  55720, 55682, 55467, 55051, 54973, 54646, 53897, 52649, 51986, 51678, 51404,
  51320, 50973, 50346, 49904, 49654, 48367, 48107, 47689, 45405, 40350, 40001,
  39456, 37845, 37064, 36798, 36547, 34567, 34145, 30462, 29204, 28406, 27067,
  27143, 27102, 27064, 27034, 27013, 26067, 24507, 23040, 21076, 20670, 20200,
  17097, 16780, 16059, 12924, 12786, 11579, 10983, 10790, 9109, 7176, 6897,
  6457, 2798, 1500,
];
export { otherUserPhones, otherUserPrices };
