import React from "react";
import { Button, Card, Carousel } from "react-bootstrap";
import "../index.css";
import moment from "moment";
import { css } from "@emotion/css";
import Prizes from "./Prizes";
import { NavLink } from "react-router-dom";

const GameCard = ({ data, onpress, servertime, eligible }) => {
  let { gameId, buyUntil, startAt, customUI, buyAtPrice, prizes, isFreeGame } = data;
  let { background, primary, promoImageURL, secondary, title } = customUI;
  const bought = isFreeGame || eligible.some((item) => item.gameId === gameId);
  const buttonBg = primary === "DEFAULT" ? "" : primary;
  const textcolor = secondary === "DEFAULT" ? "" : secondary;
  const dateString = moment.unix(startAt.seconds).format("DD MMMM YYYY h:mm a");
  return (
    <Card
      bg="Light"
      className="cardin gamecard"
      style={
        background === "DEFAULT"
          ? { background: "rgba(0, 0, 0,0.3)" }
          : { background: `${background}` }
      }
    >
      <Carousel controls={false}>
        {promoImageURL && (
          <Carousel.Item>
            <Card.Img
              style={{
                marginTop: 20,
                margin: "auto",
                height: 300,
                objectFit: "contain",
              }}
              variant="top"
              src={promoImageURL}
            />
          </Carousel.Item>
        )}
        {prizes.map((item) => (
          <Carousel.Item key={item.prizeId}>
            <Prizes customUI={customUI} data={item} />
          </Carousel.Item>
        ))}
      </Carousel>

      <Card.Body>
        <Card.Title className="whitetext" style={{ color: textcolor }}>
          {title}{" "}
        </Card.Title>
        <Card.Text
          className="whitetext2"
          style={{ color: textcolor, paddingTop: "0.5rem" }}
        >
          Gameday: {dateString}
        </Card.Text>
        <Card.Text></Card.Text>
        {bought && (
          <NavLink
            className={`bouncy1 btn Button2
            ${
              primary === "DEFAULT"
                ? "Button2 bouncy1"
                : css`
                    background: ${buttonBg};
                    color: ${textcolor};
                    &:hover {
                      background: ${textcolor};
                      color: ${buttonBg};
                    }
                  `
            }`}
            to={`/game/${gameId}`}
          >
            Goto Game Page
          </NavLink>
        )}
        {!bought && (
          <Button
            className={`bouncy1 Button2
            ${
              primary === "DEFAULT"
                ? "Button2 bouncy1"
                : css`
                    background: ${buttonBg};
                    color: ${textcolor};
                    &:hover {
                      background: ${textcolor};
                      color: ${buttonBg};
                    }
                    &:disabled {
                      background: ${buttonBg};
                      color: ${textcolor};
                    }
                  `
            }`}
            onClick={onpress}
            disabled={bought || buyUntil.seconds < servertime}
          >
            {startAt.seconds + 60 < servertime
              ? "Game Ended"
              : buyUntil.seconds > servertime
              ? `Buy game coupon at \u20B9${buyAtPrice}`
              : "Time Up"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default GameCard;
