import React, { Component } from "react";
import { auth } from "../Components/Firebase";
import Api from "../Components/Api";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import Cards from "../Components/Cards";
import Loader from "../Components/Loader";
import AdminCampaignForm from "../Components/AdminCampaignForm";
import { withRouter } from "../Components/WithRouter";

class AdminCampaignList extends Component {
  state = {
    isAdmin: false,
    isLoaded: false,
    campaigns: [],
    campaignBeingEdited: null,
    campaignBeingDeleted: null,
    campaignIdForDeletionConfirmationText: "",
    deleteError: "",
    deleting: false,
  };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      user?.getIdTokenResult().then((idTokenResults) => {
        // any truthy value will not do. it needs to be explicitly `true`
        const isAdmin = idTokenResults.claims.admin === true;
        this.setState({ isAdmin: isAdmin });
        if (isAdmin) {
          Api("/api/campaigns", {
            headers: {
              Authorization: user.accessToken,
              "Content-Type": "application/json",
            },
          }).then((response) => {
            if (response.status === 403) {
              this.setState({ isAdmin: false, isLoaded: true });
            } else {
              this.setState({ campaigns: response.data, isLoaded: true });
            }
          });
        } else {
          this.setState({ isLoaded: true });
        }
      });
    });
  }

  editCampaign(campaign) {
    this.setState({ campaignBeingEdited: campaign });
  }
  addCampaign() {
    this.setState({
      campaignBeingEdited: {
        startTimestamp: null,
        isEligibilityAtCampaignLevel: true,
        title: "",
        clientId: "marketing-demo",
        campaignId: "",
        customUI: {
          title: "",
          brandLogoURL: "",
          primary: "#673ab7",
          prizeDescription: "",
          secondary: "#e3f2fd",
          brandName: "BidTronix",
          prizesWorth: "100000",
          promoImageURL: "",
          background: ["#121212"],
        },
        isCouponsEnabled: false,
        description: "",
        buyAtPrice: 100,
        endTimestamp: null,
      },
    });
  }

  confirmCampaignDeletion(campaign) {
    this.setState({
      campaignBeingDeleted: campaign,
      campaignIdForDeletionConfirmationText: "",
    });
  }

  deleteCampaign(event) {
    this.setState({
      deleteError: "",
      deleting: true,
    });
    event.stopPropagation();
    event.preventDefault();
    const campaignBeingDeleted = this.state.campaignBeingDeleted;
    const currentUser = auth.currentUser;
    const config = {
      headers: {
        Authorization: currentUser.accessToken,
        "Content-Type": "application/json",
      },
    };
    Api.delete(`/api/campaigns/${campaignBeingDeleted.campaignId}/`, config)
      .then(() => {
        const updatedCampaigns = this.state.campaigns.filter(
          (c) => c.campaignId !== campaignBeingDeleted.campaignId
        );
        this.setState({
          campaigns: updatedCampaigns,
          campaignBeingDeleted: null,
          deleting: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          deleteError:
            err.response?.data?.error ||
            "Failed to delete campaign. Please try again.",
          deleting: false,
        });
      });
  }

  stopEdit() {
    this.setState({ campaignBeingEdited: null });
  }

  render() {
    if (!this.state.isLoaded) {
      return <Loader></Loader>;
    }
    if (!this.state.isAdmin) {
      return (
        <h1 className="h1">
          You do not have the necessary permissions to view this page.
        </h1>
      );
    }

    let navigate;
    try {
      navigate = this.props.router.navigate;
    } catch {
      throw new Error(
        "this.props.router not found. Wrap component withRouter."
      );
    }
    return (
      <>
        <h1 className="h1">
          All Campaigns
          <Button
            variant="success"
            style={{ float: "right", margin: "10px" }}
            onClick={() => this.addCampaign()}
          >
            Add Campaign
          </Button>
        </h1>
        <Stack
          className="container1 gameBackgrund"
          style={{ flexWrap: "wrap", justifyContent: "space-evenly" }}
          gap={3}
          direction={"horizontal"}
        >
          {this.state.campaigns.map((campaign) => {
            return (
              <Cards
                data={campaign}
                key={campaign.campaignId}
                canEdit={this.state.isAdmin}
                canDelete={this.state.isAdmin}
                markEnded={true}
                onEdit={() => this.editCampaign(campaign)}
                onDelete={() => this.confirmCampaignDeletion(campaign)}
                onpress={() =>
                  navigate(`/admin/campaigns/${campaign.campaignId}`)
                }
              ></Cards>
            );
          })}
        </Stack>
        {this.state.campaignBeingEdited && (
          <Modal
            show={this.state.campaignBeingEdited}
            onHide={() => this.stopEdit()}
            backdrop="static"
            keyboard={false}
            dialogClassName="max-width-615"
          >
            <Modal.Header closeButton>
              {this.state.campaignBeingEdited.campaignId
                ? `Edit ${this.state.campaignBeingEdited.campaignId}`
                : "Create New Campaign"}
            </Modal.Header>
            <Modal.Body>
              <AdminCampaignForm
                campaign={this.state.campaignBeingEdited}
                onSubmit={(campaign) => this.updateCampaign(campaign)}
              />
            </Modal.Body>
          </Modal>
        )}
        {this.state.campaignBeingDeleted && (
          <Modal
            show={this.state.campaignBeingDeleted}
            onHide={() => this.confirmCampaignDeletion(null)}
            backdrop="static"
            keyboard={false}
            dialogClassName="max-width-615"
          >
            <Modal.Header closeButton>
              {`Delete ${this.state.campaignBeingDeleted.title}?`}
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>This action is irreversible.</strong> All records of
                this campaign shall be deleted, including the logs, participants
                and payment details.
              </p>
              <p>
                You need to delete all games associated with this campaign
                before deleting the campaign itself.
              </p>
              <p>
                <em>
                  Note: Any uploaded images (prizes/promo) will not be deleted
                  as other campaigns can reuse them.
                </em>
              </p>
              <p>
                Are you <strong>sure</strong> you want to do this?
              </p>

              <Form
                noValidate
                style={{ display: "flex", flexDirection: "column" }}
                onSubmit={(e) => this.deleteCampaign(e)}
              >
                <Form.Group controlId="campaignId">
                  <Form.Label>
                    <p>
                      To confirm you want to delete this campaign, please
                      type&nbsp;
                      <code>
                        {this.state.campaignBeingDeleted.campaignId}
                      </code>{" "}
                      in the box below.
                    </p>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        campaignIdForDeletionConfirmationText: e.target.value,
                      });
                    }}
                    required
                    autoComplete="off"
                    placeholder={this.state.campaignBeingDeleted.campaignId}
                  />
                </Form.Group>
                {this.state.deleteError && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: ".875em",
                      color: "#dc3545",
                    }}
                  >
                    {this.state.deleteError}
                  </div>
                )}
                <Button
                  className="mt-2"
                  variant={
                    this.state.campaignIdForDeletionConfirmationText ===
                    this.state.campaignBeingDeleted?.campaignId
                      ? "danger"
                      : "secondary"
                  }
                  type="submit"
                  disabled={
                    this.state.campaignIdForDeletionConfirmationText !==
                    this.state.campaignBeingDeleted?.campaignId
                  }
                >
                  Delete campaign
                </Button>
              </Form>
              {this.state.deleting && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "-5rem",
                    left: "-2rem",
                    zIndex: 10,
                    width: "calc(100% + 4rem)",
                    height: "calc(100% + 8rem)",
                    background: "#000000B8",
                    color: "#FFFFFF",
                  }}
                >
                  <h3>Deleting</h3>
                  <p>This should take a few seconds</p>
                </div>
              )}
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }

  updateCampaign(campaign) {
    let matchFound = false;
    const updatedCampaigns = this.state.campaigns.map((c) => {
      if (c.campaignId === campaign.campaignId) {
        matchFound = true;
        return campaign;
      } else {
        return c;
      }
    });
    if (!matchFound) {
      updatedCampaigns.unshift(campaign);
    }
    this.setState({ campaigns: updatedCampaigns });
    this.stopEdit();
  }
}
export default withRouter(AdminCampaignList);
