import React, { Component } from "react";

import { Row, Col, Container } from "react-bootstrap";
import Cards from "../Components/Cards";
import { connect } from "react-redux";
import { fetchcampaigns, sendcampaign } from "../Store/action/auth";
import { withRouter } from "../Components/WithRouter";

class Campaigns extends Component {
  componentDidMount() {
    let timestamp = new Date();
    this.props.fetchcampaigns(timestamp, (err) => {
      if (!err) {
      }
    });
  }
  render() {
    const { activecampaigns, history, sendcampaign } = this.props;
    let navigate;
    try {
      navigate = this.props.router.navigate;
    } catch {
      throw new Error(
        "this.props.router not found. Wrap component withRouter."
      );
    }
    return (
      <Container
        fluid
        className="mt20 mb50 "
        style={{ minHeight: "100vh", maxWidth: "1200px" }}
      >
        <h4
          style={{ textAlign: "left", color: "#fff" }}
          fluid
          className="mt20 mb50"
        >
          Active Campaigns
        </h4>
        <Row className="justify-content-md-center mb50">
          {activecampaigns &&
            activecampaigns.map((item, index) => (
              <Col lg={4} sm key={index}>
                <Cards
                  onpress={() => {
                    navigate(`/campaigns/${item.campaignId}`);
                  }}
                  data={item}
                />
              </Col>
            ))}
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  activecampaigns: state.authReducer.activecampaigns,
});
const mapDispatchToProps = (dispatch) => ({
  fetchcampaigns: (data, callback) => dispatch(fetchcampaigns(data, callback)),
  sendcampaign: (data) => dispatch(sendcampaign(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Campaigns));
