import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import BeautyStars from "beauty-stars";

const Logs = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setvalue] = useState(0);

  return (
    <Container className="justify-content-md-center  " fluid>
      <p className="boldheader">GAME OVER</p>

      <Row className="justify-content-md-center  mb-5 ">
        <Col lg="6">
          <Card className="text-center mt-2 ">
            <Card.Body>
              <Table borderless className="table-fixed">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>User Name</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {props.logs.userlist.map(
                    (item, index) =>
                      index < 8 && (
                        <tr>
                          <td>{item.rank}</td>
                          <td>{item.number}</td>
                          <td>{item.price}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </Table>
              <p className="  boldresult">
                Winner of Game is {props.logs.userlist[0].number}
              </p>
              <Button
                className="Button2"
                onClick={() => props.history.push("/demo")}
              >
                Play Again
              </Button>
              <Button className="Button2" onClick={handleShow}>
                Feedback
              </Button>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>We Value your Opinion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ margin: "5%" }}>
                    <BeautyStars
                      activeColor="#4F56F9"
                      size="25px"
                      value={value}
                      onChange={(value) => setvalue(value)}
                    />
                  </div>

                  <Form>
                    <Form.Control as="textarea" rows="3" />
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
                  <Button variant="primary">Submit</Button>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  logs: state.authReducer,
});

export default connect(mapStateToProps)(Logs);
