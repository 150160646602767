import { Component } from "react";
import { auth, storage } from "../Components/Firebase";
import Api from "../Components/Api";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

export default class AdminCampaignForm extends Component {
  constructor(props) {
    super(props);
    this.isCreate = !props.campaign.campaignId;
    this.state = {
      validated: false,
      saving: false,
      imageUploadError: false,
      saveError: false,
      campaign: {
        ...props.campaign,
        customUI: { ...props.campaign.customUI },
      },
    };
    if (!this.state.campaign.clientId) {
      this.state.campaign.clientId = "marketing-demo";
    }
    if (this.state.campaign.isCouponsEnabled === undefined) {
      this.state.campaign.isCouponsEnabled = false;
    }
    this.state.campaign.customUI.background =
      this.state.campaign.customUI.background[0];
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        value = target.files[0];
        break;
      default:
        value = target.value;
    }
    let name = target.id;
    let attr;
    if (name.startsWith("customUI")) {
      [name, attr] = name.split(".");
      value = { ...this.state.campaign.customUI, [attr]: value };
    }

    this.setState({
      campaign: {
        ...this.state.campaign,
        [name]: value,
      },
    });
  }

  async handleSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    const form = event.currentTarget;
    this.setState({
      validated: true,
      imageUploadError: false,
      saveError: false,
    });
    if (!form.checkValidity()) {
      return;
    }
    this.setState({ saving: true });
    let imageURL;
    if (typeof this.state.campaign.customUI.promoImageURL === "string") {
      imageURL = this.state.campaign.customUI.promoImageURL;
    } else {
      const ext = this.state.campaign.customUI.promoImageURL.name
        .split(".")
        .pop();
      const storageRef = ref(
        storage,
        `prizes/${this.state.campaign.campaignId}.${ext}`
      );
      try {
        const snapshot = await uploadBytes(
          storageRef,
          this.state.campaign.customUI.promoImageURL
        );
        imageURL = await getDownloadURL(snapshot.ref);
      } catch {
        this.setState({
          saving: false,
          imageUploadError: true,
        });
        return;
      }
    }
    const data = {
      ...this.state.campaign,
      customUI: {
        ...this.state.campaign.customUI,
        title: this.state.campaign.title,
        brandLogoURL: "",
        prizeDescription: this.state.campaign.description,
        brandName: "BidTronix",
        promoImageURL: imageURL,
        background: [this.state.campaign.customUI.background],
      },
    };

    let method, url;
    if (this.isCreate) {
      method = "post";
      url = "/api/campaigns";
    } else {
      method = "patch";
      url = `/api/campaigns/${data.campaignId}`;
    }

    const currentUser = auth.currentUser;
    const config = {
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: currentUser.accessToken,
        "Content-Type": "application/json",
      },
    };
    Api.request(config)
      .then(() => {
        this.setState({
          campaign: { ...data },
          saving: false,
        });
        if (this.props.onSubmit) {
          this.props.onSubmit(data);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          saveError: true,
          saving: false,
        });
      });
  }

  renderDatePicker(field, label) {
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Datetime
          displayTimeZone={"Asia/Kolkata"}
          value={moment(this.state.campaign[field])}
          onChange={(newDate) => {
            if (typeof newDate !== "string") {
              this.setState({
                campaign: {
                  ...this.state.campaign,
                  [field]: newDate,
                },
              });
            }
          }}
          inputProps={{ required: true }}
          renderInput={(p, _o, _c) => (
            <>
              <Form.Control {...p}></Form.Control>
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </>
          )}
        ></Datetime>
      </Form.Group>
    );
  }

  render() {
    return (
      <Card className="cardcampaign">
        <Card.Body>
          <Form
            noValidate
            validated={this.state.validated}
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Form.Group controlId="campaignId">
              <Form.Label>Campaign ID</Form.Label>
              <Form.Control
                type="text"
                value={this.state.campaign.campaignId}
                disabled={!this.isCreate}
                onChange={this.handleInputChange}
                required
              />
              <Form.Text>
                This will be used to uniquely identify the campaign and as part
                of the URL
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={this.state.campaign.title}
                onChange={this.handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={this.state.campaign.description}
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <InputGroup style={{ flexWrap: "nowrap" }}>
              {this.renderDatePicker("startTimestamp", "Start")}
              {this.renderDatePicker("endTimestamp", "End")}
            </InputGroup>
            <Form.Group controlId="isEligibilityAtCampaignLevel">
              <Form.Check
                checked={this.state.campaign.isEligibilityAtCampaignLevel}
                onChange={this.handleInputChange}
                inline={true}
                label="Eligibility is at Campaign Level"
              />
            </Form.Group>
            <Form.Group controlId="isCouponsEnabled">
              <Form.Check
                checked={this.state.campaign.isCouponsEnabled}
                inline={true}
                readOnly={true}
                disabled={true}
                label="Coupons are enabled"
              />
            </Form.Group>
            <InputGroup
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Form.Group controlId="customUI.background">
                <Form.Label>Background</Form.Label>
                <Form.Control
                  type="color"
                  value={this.state.campaign.customUI.background}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="customUI.primary">
                <Form.Label>Button</Form.Label>
                <Form.Control
                  type="color"
                  value={this.state.campaign.customUI.primary}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="customUI.secondary">
                <Form.Label>Text</Form.Label>
                <Form.Control
                  type="color"
                  value={this.state.campaign.customUI.secondary}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            </InputGroup>
            <Form.Group controlId="buyAtPrice">
              <Form.Label>Entry Fee</Form.Label>
              <InputGroup>
                <InputGroup.Text>₹</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={this.state.campaign.buyAtPrice}
                  required
                  onChange={this.handleInputChange}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <hr />
            <section>
              <h5>Prizes</h5>
              <Form.Group controlId="customUI.promoImageURL" className="mb-3">
                <Form.Label>Promo Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={this.handleInputChange}
                />
                <Form.Text>
                  Existing image:{" "}
                  <a
                    href={this.props.campaign.customUI.promoImageURL}
                    target="_blank"
                  >
                    {this.props.campaign.customUI.promoImageURL}
                  </a>
                </Form.Text>
                {this.state.imageUploadError && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: ".875em",
                      color: "#dc3545",
                    }}
                  >
                    Failed to upload image. Please try again.
                  </div>
                )}
              </Form.Group>
              <Form.Group controlId="customUI.prizesWorth">
                <Form.Label>Prizes Worth</Form.Label>
                <InputGroup>
                  <InputGroup.Text>₹</InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={this.state.campaign.customUI.prizesWorth}
                    required
                    onChange={this.handleInputChange}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </section>
            {this.state.saveError && (
              <div
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: ".875em",
                  color: "#dc3545",
                }}
              >
                Unable to save campaign. Please try again.
              </div>
            )}
            <Button
              className="BuyButton"
              type="submit"
              disabled={this.state.saving}
            >
              Submit
            </Button>
          </Form>
          {this.state.saving && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "-5rem",
                left: "-2rem",
                zIndex: 10,
                width: "calc(100% + 4rem)",
                height: "calc(100% + 8rem)",
                background: "#000000B8",
                color: "#FFFFFF",
              }}
            >
              <h3>Saving</h3>
              <p>This should take a few seconds</p>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }
}
