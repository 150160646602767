import React, { Component } from "react";
import { Image, Nav, Navbar } from "react-bootstrap";
import { auth, onMessageListener } from "./Firebase";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });
    });
  }

  signOut() {
    auth.signOut();
  }

  render() {
    onMessageListener()
      .then((payload) => {
        // console.log("hhhhe", payload);
        const { title, body } = payload.data;
        // toast.info(`${title}; ${body}`);
      })
      .catch((err) => {
        // toast.error(JSON.stringify(err));
      });
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Nav className="mr-auto">
          {
            <Nav.Link href="/">
              <Image
                roundedCircle
                style={{
                  width: 90,
                  height: 50,
                  objectFit: "cover",
                }}
                src={require("../resources/icons/logo-med.png")}
              />
            </Nav.Link>
          }
        </Nav>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {!!this.state.currentUser && (
              <Nav.Link href="/profile">
                <Image
                  roundedCircle
                  style={{ width: 30, height: 30 }}
                  src={
                    this.state.currentUser.photoURL ||
                    require("../resources/images/avatar-placeholder.png")
                  }
                />
              </Nav.Link>
            )}
            <Nav.Link href="/demo">Offline Demo</Nav.Link>
            {!!this.state.currentUser && (
              <Nav.Link href="/livedemo">Live Demo Game</Nav.Link>
            )}
            <Nav.Link href="/campaigns">Campaigns</Nav.Link>
            {/* <Nav.Link href="#BuyCoupon">Buy Coupon</Nav.Link>
        <Nav.Link href="#Winners"> Winners</Nav.Link>
         */}
            <Nav.Link href="/aboutus">About Us</Nav.Link>
            {!!this.state.currentUser && (
              <Nav.Link onClick={this.signOut} href="/">
                Logout
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Navigation;
