import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import GameCard from "../Components/GameCard";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendpurchase } from "../Store/action/auth";

const Upcominggames = (props) => {
  const navigate = useNavigate();
  let { upcoming } = props;
  return (
    <Container
      fluid
      className="mt20 mb50"
      style={{ minHeight: "100vh", maxWidth: "1200px" }}
    >
      <h4 style={{ textAlign: "center" }} fluid className="mt20 mb50">
        Upcoming Games
      </h4>
      <Row className="justify-content-md-center">
        {upcoming.length === 0 && (
          <p className="boldheading">No Upcoming Games</p>
        )}
        {upcoming.map((item, index) => (
          <Col lg={4} sm key={index}>
            <GameCard
              onpress={() => {
                props.sendpurchase(item);
                navigate("/purchase");
              }}
              data={item}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  upcoming: state.authReducer.upcoming,
});
const mapDispatchToProps = (dispatch) => ({
  sendpurchase: (data, callback) => dispatch(sendpurchase(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upcominggames);
