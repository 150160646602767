import React from "react";
import { Container } from "react-bootstrap";

const Prizes = (props) => {
  let { data, customUI } = props || {};
  let { primary, secondary } = customUI || {};
  const buttonBg = primary === "DEFAULT" ? "" : primary;
  const textcolor = secondary === "DEFAULT" ? "" : secondary;
  return (
    <Container fluid style={{ width: 320, margin: "auto" }}>
      <div className="ribbon ribbon-top-left">
        <span style={{ backgroundColor: buttonBg, color: textcolor }}>
          {data.gift} prize
        </span>
      </div>
      <img
        style={{ width: 300, height: 300, objectFit: "contain" }}
        className="d-block prizes"
        src={data.images && data.images[0]}
      />
    </Container>
  );
};

export default Prizes;
