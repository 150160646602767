import { Component } from "react";
import { otherUserPhones, otherUserPrices } from "../resources/Constant";
import { auth, firestore } from "./Firebase";
import { collection, doc, orderBy, getDocs, query } from "firebase/firestore";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";

export default class GameLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      result: "",
      generating: true,
      showPrizeModal: false,
    };
    this.isDemoGameLog = this.props.gameId === "Demo game";

    if (this.isDemoGameLog) {
      this.rankTheDemoPlayers().then();
    } else {
      this.rankThePlayers().then();
    }
  }

  async rankThePlayers() {
    let players = [];
    const thisGame = doc(firestore, "games", this.props.gameId);
    getDocs(
      query(collection(thisGame, "logs"), orderBy("lockedPrice", "desc"))
    ).then((docs) => {
      docs.forEach((_doc) => players.push(_doc.data()));

      const totalPlayers = players.length;
      const currentUser = auth.currentUser;
      const currentUserId = currentUser.uid;
      let userRank = -1;
      let userPrice = null;
      for (let i = 0; i <= totalPlayers; i++) {
        if (players[i] && players[i].userId === currentUserId) {
          userRank = i + 1;
          userPrice = players[i].lockedPrice;
          // userAccepted = players[i].didAcceptPrize;
        }
      }

      const result =
        userRank === -1
          ? "You didn't click on the button!"
          : userRank === 1
          ? `YOU HAVE WON THE PRIZE${userPrice ? " AT ₹" + userPrice : ""}!!!`
          : `You have ranked #${userRank}!`;
      if (userRank === 1) {
        this.setState({ showPrizeModal: true });
      }
      this.setState({
        userList: players,
        result: result,
        generating: false,
      });
    });
  }

  async rankTheDemoPlayers() {
    const userPrice = this.props.userPrice || -1;
    const userPhone = this.props.userPhone;
    this.state = { generating: true };

    const totalPlayers = otherUserPrices.length;
    let userToBeAdded = true;
    let userRank = -1;
    let userlist = [];
    for (let i = 1; i <= totalPlayers; i++) {
      if (userToBeAdded && userPrice > otherUserPrices[i - 1]) {
        userRank = i;
        userToBeAdded = false;
        userlist.push({ rank: i, number: userPhone, price: userPrice });
      } else {
        userlist.push({
          rank: i,
          number: otherUserPhones[i - 1],
          price: otherUserPrices[i - 1],
        });
      }
    }
    const result =
      userRank === -1
        ? "You didn't click on the button!"
        : userRank === 1
        ? `YOU HAVE WON THE PRIZE AT ₹${userPrice}!!!`
        : `You have ranked #${userRank}!`;
    this.state = {
      generating: false,
      userList: userlist,
      result: result,
    };
  }
  getvalue = (id) => {
    if (id) {
      const r = /(\+\d+)\d{6}(\d{4})/g;
      return id.replace(r, "$1******$2");
    }
  };

  render = () => {
    if (this.state.generating) {
      return <p className="  boldresult">{"Calculating Result ....."}</p>;
    }

    if (this.isDemoGameLog) {
      return (
        <>
          <p className="boldresult">{this.state.result}</p>
          <ul className="demoLogList jrRegularFill" style={{ height: "40vh" }}>
            <li className="listHeader demoLogEntry">
              <p className="textwhite">Rank</p>
              <p className="textwhite">User Name</p>
              <p className="textwhite">Price</p>
            </li>
            {this.state.userList.map((user, index) => (
              <li
                className="demoLogEntry"
                key={user.number}
                style={
                  user.number === this.props.userPhone
                    ? { backgroundColor: "red" }
                    : {}
                }
              >
                <p className="textwhite m-0">{user.rank}</p>
                <p className="textwhite m-0">{user.number}</p>
                <p className="textwhite m-0">₹{user.price}</p>
              </li>
            ))}
          </ul>
        </>
      );
    }

    const currentUserId = auth.currentUser.uid;
    return (
      <>
        <Modal
          show={this.state.showPrizeModal}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>
              You have won an Assured Prize!{" "}
              {!this.props.disableAcceptingPrize && (
                <>
                  If you choose to accept it, we will contact you with the
                  details. Do you accept it?
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.props.disableAcceptingPrize && (
              <Button
                className={"Button2"}
                type="submit"
                onClick={() => {
                  this.setState({ showPrizeModal: false });
                  this.props.onPrizeAccepted();
                }}
              >
                Yes
              </Button>
            )}
            <Button
              className={"Button2"}
              type="submit"
              onClick={() => {
                this.setState({ showPrizeModal: false });
              }}
            >
              {this.props.disableAcceptingPrize ? "Okay" : "No"}
            </Button>
          </Modal.Body>
        </Modal>
        <p className="boldresult">{this.state.result}</p>
        <ul className="demoLogList jrRegularFill" style={{ height: "40vh" }}>
          <li className="listHeader demoLogEntry">
            <p className="textwhite">User Name</p>
            <p className="textwhite">Locked Price</p>
            <p className="textwhite">Locked Time</p>
          </li>
          {this.state.userList.map((user, index) => (
            <li
              className="demoLogEntry"
              key={user.userId}
              style={
                user.userId === currentUserId ? { backgroundColor: "red" } : {}
              }
            >
              <p className="textwhite textwraping m-0">
                {user.userId === currentUserId
                  ? user.phoneNumber
                  : this.getvalue(user.phoneNumber)}
              </p>
              <p className="textwhite m-0">₹{user.lockedPrice}</p>
              <p className="textwhite m-0">
                {moment
                  .unix(user.lockedTimestamp * 0.001)
                  .format("h:mm:ss.SS a")}
              </p>
            </li>
          ))}
        </ul>
      </>
    );
  };
}
