import React, { Component } from "react";
import { Button, Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import {
  gethistorydata,
  sendpurchase,
  upcominggame,
} from "../Store/action/auth";
import { connect } from "react-redux";
import Loader from "../Components/Loader";
import "react-component-countdown-timer/lib/styles.css";
import Api from "../Components/Api";
import GameCard from "../Components/GameCard";
import RealtimeClock from "../Components/RealtimeClock";
import QRPaymentModal from "../Components/QRPaymentModal";
import { css } from "@emotion/css";
import Prizes from "../Components/Prizes";
import { auth, firestore } from "../Components/Firebase";
import Timer from "react-compound-timer";
import { collection, getDocs, query, where } from "firebase/firestore";
import { withRouter } from "../Components/WithRouter";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showStart: false,
      shouldShowQRCode: false,
    };
  }

  componentDidMount() {
    const { gethistorydata } = this.props || {};
    const currentUser = auth.currentUser;
    gethistorydata(currentUser.uid, (err) => {
      if (err.pass) {
      }
    });

    const campaignId = this.props.router.params.id;
    getDocs(
      query(
        collection(firestore, "campaigns"),
        where("campaignId", "==", campaignId)
      )
    ).then((res) => {
      res.forEach((doc) => {
        let campaigndata = doc.data();
        this.setState({ campaigndata });
      });
    });
    this.props.upcominggame(campaignId, (err) => {
      if (err.pass) {
        if (err.value) {
          Api.get(`/api/getGameDetails?gameId=${err.value}`, {
            headers: {
              Authorization: currentUser.accessToken,
            },
          })
            .then((res) => {
              let data = res.data && res.data.data;
              let servertime = (data.serverTimestamp * 0.001).toPrecision(10);
              let livetime = data.startAt._seconds - servertime;
              let buyUntil = data && data.buyUntil;
              this.setState({
                gamedata: data,
                loading: false,
                livetime,
                servertime,
                nocampaign: false,
                buyUntil,
              });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        } else {
          this.setState({ nocampaign: true });
          this.setState({ loading: false });
        }
      }
    });
  }

  showQRCode() {
    this.setState({ shouldShowQRCode: true });
  }

  hideQRCode() {
    this.setState({ shouldShowQRCode: false });
  }

  onPaymentConfirmed() {
    this.hideQRCode();
    const currentUser = auth.currentUser;
    this.props.gethistorydata(currentUser.uid, (err) => {
      if (err.pass) {
      }
    });
  }

  onPaymentCancelled() {
    this.hideQRCode();
  }

  render() {
    const { history, upcoming, eligible } = this.props || {};
    let navigate;
    try {
      navigate = this.props.router.navigate;
    } catch {
      throw new Error(
        "this.props.router not found. Wrap component withRouter."
      );
    }
    const {
      loading,
      gamedata,
      livetime,
      nocampaign,
      servertime,
      showStart,
      campaigndata,
      buyUntil,
    } = this.state;
    const { customUI, gameId, prizes } = gamedata || {};
    const { primary, secondary, background } = customUI || {};

    // const buttonBg = primary === "DEFAULT" ? "" : primary;
    const textcolor = secondary === "DEFAULT" ? "" : secondary;
    // const bgColor = background === "DEFAULT" ? "" : background;
    const cbuttonBg =
      primary === "DEFAULT"
        ? ""
        : campaigndata &&
          campaigndata.customUI &&
          campaigndata.customUI.primary;
    const ctextcolor =
      secondary === "DEFAULT"
        ? ""
        : campaigndata &&
          campaigndata.customUI &&
          campaigndata.customUI.secondary;
    const cbgColor =
      background === "DEFAULT"
        ? ""
        : campaigndata &&
          campaigndata.customUI &&
          campaigndata.customUI.background &&
          campaigndata.customUI.background.length === 1
        ? campaigndata &&
          campaigndata.customUI &&
          campaigndata.customUI.background[0]
        : `linear-gradient(${
            campaigndata &&
            campaigndata.customUI &&
            campaigndata.customUI.background[0]
          },${
            campaigndata &&
            campaigndata.customUI &&
            campaigndata.customUI.background[1]
          })`;

    let styles = {
      fontSize: "30px",
      marginTop: "10px",
      color:
        textcolor === ""
          ? "white"
          : campaigndata &&
            campaigndata.customUI &&
            campaigndata.customUI.secondary,
      textAlign: "center",
    };
    return (
      <Container
        fluid
        className="container1 gameBackgrund"
        style={{ minHeight: "100vh", background: cbgColor }}
      >
        <Modal show={showStart} centered backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Purchased successfull.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              className={"BuyButton"}
              type="submit"
              onClick={() => {
                window.location.reload();
                this.setState({ showStart: false });
              }}
            >
              Close
            </Button>
          </Modal.Body>
        </Modal>
        {this.state.shouldShowQRCode && (
          <QRPaymentModal
            game={gamedata}
            onPaymentConfirmed={() => this.onPaymentConfirmed()}
            onPaymentCancelled={() => this.onPaymentCancelled()}
          ></QRPaymentModal>
        )}
        {loading && <Loader />}
        {nocampaign && (
          <Container className="container1">
            <p className="boldheader">NO ACTIVE GAME</p>
          </Container>
        )}
        {!loading && !nocampaign && (
          <>
            <RealtimeClock
              time={servertime}
              customUI={campaigndata && campaigndata.customUI}
            />

            <Row className="justify-content-md-center">
              <Col lg="4" style={{ margin: "auto" }}>
                <div style={styles}>
                  <label>
                    <strong>
                      {gamedata && gamedata.customUI && gamedata.customUI.title}
                    </strong>
                  </label>
                </div>

                <div className="text-center withoutborder">
                  <Carousel
                    controls={false}
                    style={{ width: 320, margin: "auto" }}
                  >
                    {prizes &&
                      prizes.map((prize) => (
                        <Carousel.Item
                          key={prize.prizeId}
                          style={{
                            width: 320,
                            height: 320,
                          }}
                        >
                          <Prizes
                            customUI={campaigndata && campaigndata.customUI}
                            data={prize}
                          />
                        </Carousel.Item>
                      ))}
                  </Carousel>

                  {/* <Card.Body>
                    <Card.Title>
                      <strong>Rs. 1,50,000</strong>
                    </Card.Title>
                  </Card.Body> */}
                </div>
              </Col>

              <Col lg="5">
                <div style={styles}>
                  <label
                    style={{
                      fontSize: "30px",
                      color: ctextcolor,
                    }}
                  >
                    Next Game Starts in
                    <div className="bouncy">
                      <Timer initialTime={livetime * 1000} direction="backward">
                        {() => (
                          <React.Fragment>
                            <Timer.Days />d {": "}
                            <Timer.Hours />h {": "}
                            <Timer.Minutes />m {": "}
                            <Timer.Seconds />s
                          </React.Fragment>
                        )}
                      </Timer>
                    </div>
                  </label>
                  <Button
                    onClick={() => navigate(`/game/${gameId}`)}
                    // className=" playButton"
                    // style={{ background: buttonBg, color: textcolor }}
                    className={`playButton
                    ${
                      primary === "DEFAULT"
                        ? "playButton"
                        : css`
                            background: ${cbuttonBg};
                            color: ${ctextcolor};
                            &:hover {
                              background: ${ctextcolor};
                              color: ${cbuttonBg};
                            }
                          `
                    }`}
                  >
                    Take me to the Next Game
                  </Button>
                </div>

                <div style={styles}>
                  <label
                    style={{
                      fontSize: "30px",
                      color: ctextcolor,
                    }}
                  >
                    Confused on How to Play?
                  </label>
                  <Button
                    // className="playButton"
                    onClick={() => navigate("/demo")}
                    // style={{ background: buttonBg, color: textcolor }}
                    className={`playButton
                    ${
                      primary === "DEFAULT"
                        ? "playButton"
                        : css`
                            background: ${cbuttonBg};
                            color: ${ctextcolor};
                            &:hover {
                              background: ${ctextcolor};
                              color: ${cbuttonBg};
                            }
                          `
                    }`}
                  >
                    Try a FREE Demo Game Now
                  </Button>
                </div>
              </Col>

              <Col lg="1"></Col>
            </Row>
            <Container
              fluid
              className="mt20 mb50"
              style={{ minHeight: "100vh", maxWidth: "1200px" }}
            >
              <h4
                style={{
                  textAlign: "center",
                  color: ctextcolor,
                }}
                className="mt20 whitetext mb50"
              >
                All Games
              </h4>
              <Row className="justify-content-md-center">
                {upcoming && upcoming.length === 0 && (
                  <p className="boldheading">No Games</p>
                )}
                {upcoming &&
                  upcoming.map((game) => (
                    <Col lg={4} sm key={game.gameId}>
                      <GameCard
                        history={history}
                        eligible={eligible}
                        servertime={servertime}
                        buyUntil={buyUntil}
                        onpress={() => {
                          this.showQRCode(game);
                          // history.push(`/purchase/${item.gameId}`);
                        }}
                        data={game}
                      />
                    </Col>
                  ))}
              </Row>
            </Container>
          </>
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  upcoming: state.authReducer.upcoming,
  eligible: state.authReducer.eligible,
});

const mapDispatchToProps = (dispatch) => ({
  upcominggame: (data, callback) => dispatch(upcominggame(data, callback)),
  sendpurchase: (data, callback) => dispatch(sendpurchase(data, callback)),
  gethistorydata: (data, callback) => dispatch(gethistorydata(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Homepage));
