/*
 * Please note that this is a temporary measure built on the request of Ozzy.
 * It is bad as it doesn't integrate with a real payment gateway and everything
 * is controlled in the frontend itself based on simple confirmation by the user.
 * Please git-blame this line to get the original RazorPay integration code that
 * was originally used (but broken due to the account not being activated)
 */

import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
  collection,
  where,
  query,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { firestore, auth } from "./Firebase";
import Loader from "./Loader";

const upiId = "bidtronix.7217@waicici";
const upiAmount = 10.0;

export default class QRPaymentModal extends Component {
  constructor(props) {
    super(props);
    this.qrCode = require("../resources/images/upi-qr/bidtronix-10rs.png");
    this.state = {
      disableButtons: false,
      loaded: false,
    };
    this.confirmPayment = this.confirmPayment.bind(this);
    this.cancelPayment = this.cancelPayment.bind(this);
    this.initializePurchase().then((purchaseId) => {
      this.purchaseId = purchaseId;
      this.randomPaymentId = (Math.random() * 10e6).toFixed();
      this.upiUrl = `upi://pay?pa=${upiId}&pn=Play%20to%20Win&tr=${this.randomPaymentId}&tn=Game%20Coupon&am=${upiAmount}&cu=INR`;
      this.setState({ loaded: true });
    });
  }

  async initializePurchase() {
    const currentUser = auth.currentUser;
    const firebaseObject = {
      userId: currentUser.uid,
      campaignId: this.props.game.campaignId,
      gameId: this.props.game.gameId,
      userName: currentUser.displayName,
      status: "NEW",
      amount: this.props.game.buyAtPrice,
      isEligibilityAtCampaignLevel:
        this.props.game.isEligibilityAtCampaignLevel,
      timestamp: serverTimestamp(),
    };
    const firebaseResponse = await addDoc(
      collection(firestore, "purchases"),
      firebaseObject
    );
    return firebaseResponse.id;
  }

  async confirmPayment() {
    this.setState({ disableButtons: true });
    const currentUser = auth.currentUser;
    const userId = currentUser.uid;
    let games = [];
    if (this.props.game.isEligibilityAtCampaignLevel) {
      games = await getDocs(
        query(
          collection(firestore, "games"),
          where("campaignId", "==", this.props.game.campaignId)
        )
      );
    } else {
      games = await getDocs(
        query(
          collection(firestore, "games"),
          where("campaignId", "==", this.props.game.campaignId),
          where("gameId", "==", this.props.game.gameId)
        )
      );
    }
    const batch = writeBatch(firestore);
    games.forEach((gameRef) => {
      const gameData = gameRef.data();
      const object = {
        userId: userId,
        gameId: gameRef.id,
        didAcceptPrize: false,
        status: "PURCHASED",
        startAt: new Date(gameData.startAt.toMillis()),
        paymentId: this.randomPaymentId, // since we don't get this info when doing non-API UPI transactions
      };
      batch.set(
        doc(doc(firestore, "games", gameRef.id), "logs", userId),
        object
      );
      batch.set(
        doc(doc(firestore, "users", userId), "history", gameRef.id),
        object
      );
    });
    await batch.commit();
    await updateDoc(doc(firestore, "purchases", this.purchaseId), {
      status: "SUCCESS",
      warning: "UPI Payment manually confirmed by user.",
      timestamp: serverTimestamp(),
    });
    if (this.props.onPaymentConfirmed) {
      this.props.onPaymentConfirmed();
    }
  }

  async cancelPayment() {
    this.setState({ disableButtons: true });
    await updateDoc(doc(firestore, "purchases", this.purchaseId), {
      status: "CANCELLED",
      timestamp: serverTimestamp(),
    });
    if (this.props.onPaymentCancelled) {
      this.props.onPaymentCancelled();
    }
  }

  render() {
    return (
      <Modal show={true} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Buy Game Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body className="align-self-center">
          {this.state.loaded && (
            <>
              <img src={this.qrCode} alt={upiId} style={{ maxWidth: "100%" }} />
              <p className="payment-link">
                Paying from the same device?{" "}
                <a href={this.upiUrl}>Click here to open your payment app.</a>{" "}
              </p>
            </>
          )}
          {!this.state.loaded && <Loader></Loader>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={"BuyButton"}
            type="submit"
            onClick={this.confirmPayment}
            disabled={this.state.disableButtons}
          >
            I have paid
          </Button>
          <Button
            className={"BuyButton BuyButton--cancel"}
            type="reset"
            onClick={this.cancelPayment}
            disabled={this.state.disableButtons}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
