import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./Screens/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import { persistor, store } from "./Store/store";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import app from "./Components/Firebase";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./Screens/App.css";

const rrfConfig = {};
const rrfProps = {
  firebase: app,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </ReactReduxFirebaseProvider>
  </Provider>
);

function onServiceWorkerUpdate(registration) {
  function forceReload() {
    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload(true);
  }
  const updateMessageDiv = document.createElement("div");
  updateMessageDiv.className = "d-flex flex-column";
  updateMessageDiv.style.zIndex = 1500;
  updateMessageDiv.style.position = "sticky";
  updateMessageDiv.style.bottom = 0;
  updateMessageDiv.style.width = "100%";
  updateMessageDiv.style.padding = "2em";
  updateMessageDiv.style.background = "white";
  updateMessageDiv.innerHTML = `<h3>A newer version of Play to Win is available.</h3><button class="Button2" id="updateMessageBtn">Reload Now</button>`;
  document.body.appendChild(updateMessageDiv);
  document
    .getElementById("updateMessageBtn")
    .addEventListener("click", forceReload);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: onServiceWorkerUpdate,
});
