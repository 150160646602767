import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { gethistorydata } from "../Store/action/auth";
import Loader from "../Components/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { auth } from "../Components/Firebase";

const Profile = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const currentUser = auth.currentUser;
  useEffect(() => {
    props.gethistorydata(currentUser.uid, (err) => {
      if (err.pass) {
        setLoader(false);
      }
    });
  }, []);
  const [key, setKey] = useState("Eligible Game");
  return (
    <Container
      fluid="lg"
      className="container1"
      style={{ minHeight: "100vh", margin: "auto" }}
    >
      {loader && <Loader />}
      {!loader && (
        <div className="profile-wrapper">
          <Row style={{ marginBottom: "5px" }}>
            <img
              className="profile-image"
              style={{ borderRadius: "50%" }}
              src={
                currentUser.photoURL ||
                require("../resources/images/avatar-placeholder.png")
              }
              alt="profile"
            />
          </Row>
          <div className="emailalign whitetext">
            <label>{currentUser.email}</label>
            <label>{currentUser.phoneNumber}</label>
          </div>

          <Row className="justify-content-md-center tabs1">
            <Col lg="4">
              <Button
                className={`${
                  key === "Eligible Game" ? "buttonactive" : "buttonnonactive"
                }`}
                onClick={() => setKey("Eligible Game")}
              >
                {"Eligible Game"}
              </Button>
            </Col>
            <Col lg="4">
              <Button
                className={`${
                  key === "Game History" ? "buttonactive" : "buttonnonactive"
                }`}
                onClick={() => setKey("Game History")}
              >
                {"Game History"}
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt20">
            <Col lg="6">
              {key === "Eligible Game" && (
                <Table hover responsive>
                  {props.eligible && props.eligible.length === 0 && (
                    <thead>
                      <tr>
                        <th className="whitetext">
                          {" "}
                          You have not bought a coupon.
                        </th>
                      </tr>
                    </thead>
                  )}
                  {props.eligible && props.eligible.length !== 0 && (
                    <thead>
                      <tr>
                        <th className="whitetext">Game Id</th>
                        <th className="whitetext">Game Day</th>
                      </tr>
                    </thead>
                  )}
                  {props.eligible && (
                    <tbody>
                      {props.eligible.map((item) => (
                        <tr
                          className="tablehover textwhite"
                          onClick={() => navigate(`/game/${item.gameId}`)}
                          key={item.gameId}
                        >
                          <td>{item.gameId}</td>
                          <td>
                            {moment
                              .unix(item.startAt && item.startAt.seconds)
                              .format("DD MMMM YYYY h:mm a")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              )}

              {key === "Game History" && (
                <Table hover responsive>
                  {props.historys && props.historys.length === 0 && (
                    <thead>
                      <tr>
                        <th className="whitetext">
                          {" "}
                          You have not played a game yet
                        </th>
                      </tr>
                    </thead>
                  )}
                  {props.historys && props.historys.length !== 0 && (
                    <thead>
                      <tr>
                        <th className="whitetext">Game </th>
                        <th className="whitetext">Locked Price</th>
                        <th className="whitetext">Locked Time </th>
                      </tr>
                    </thead>
                  )}
                  {props.historys && (
                    <tbody>
                      {props.historys
                        .filter((item) => !!item)
                        .map((item) => (
                          <tr
                            className="tablehover textwhite"
                            key={item.gameId}
                          >
                            <td>{item.gameId}</td>
                            <td>{item.lockedPrice}</td>
                            <td>
                              {moment
                                .unix(item.lockedTimestamp * 0.001)
                                .format("h:mm:ss:SS")}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </Table>
              )}
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  historys: state.authReducer.historys,
  eligible: state.authReducer.eligible,
});
const mapDispatchToProps = (dispatch) => ({
  gethistorydata: (data, callback) => dispatch(gethistorydata(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
