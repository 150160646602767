import {
  ACTIVECAMPAIGNS,
  FETCHACTIVEGAMES,
  SENDCAMPAIGNS,
  SENDRESULT,
  SENDTOKEN,
  SENDTOPURHASE,
  STOREUSER,
  USERHISTORY,
} from "./actionType";
import { firestore } from "../../Components/Firebase";
import Api from "../../Components/Api";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

export const storeuser = (data) => (dispatch) => {
  try {
    dispatch({
      type: STOREUSER,
      payload: data,
    });
  } catch (err) {}
};

export const sendresult = (data) => (dispatch) => {
  try {
    dispatch({
      type: SENDRESULT,
      payload: data,
    });
  } catch (err) {}
};
export const fetchcampaigns = (data, callback) => (dispatch) => {
  try {
    let time = Math.round(+new Date() / 1000);
    let col = [];
    getDocs(
      query(
        collection(firestore, "campaigns"),
        where("endTimestamp", ">=", data)
      )
    )
      .then((res) => {
        res.forEach(function (doc) {
          let data = doc.data();
          if (data.startTimestamp.seconds < time) {
            col.push(doc.data());
          }
        });
        dispatch({
          type: ACTIVECAMPAIGNS,
          payload: col,
        });
        callback();
      })
      .catch((err) => {});
  } catch (err) {
    callback(err);
  }
};
export const gethistorydata = (data, callback) => (dispatch) => {
  try {
    const historys = [],
      eligible = [];

    const user = doc(firestore, "users", data);
    getDocs(query(collection(user, "history"))).then((docs) => {
      docs.forEach((_doc) => {
        let game = _doc.data();
        game.gameId = _doc.id;
        if (game && game.status === "PURCHASED") {
          eligible.push(game);
        } else {
          historys.push(game);
        }
      });
      callback({ pass: true });
      dispatch({
        type: USERHISTORY,
        payload: { historys, eligible },
      });
    });
  } catch (err) {
    callback({ pass: false });
  }
};
export const upcominggame = (data, callback) => (dispatch) => {
  try {
    let col = [],
      valueSelected = false;
    let value;
    getDocs(
      query(collection(firestore, "games"), where("campaignId", "==", data))
    ).then((res) => {
      res.forEach(function (doc) {
        let val = doc.data();
        if (!valueSelected) {
          if (val.status === "SCHEDULED") {
            value = val.gameId;
            valueSelected = true;
          }
        }
        col.push(val);
      });
      dispatch({
        type: FETCHACTIVEGAMES,
        payload: col,
      });
      callback({ pass: true, value });
    });
  } catch (err) {
    callback({ pass: false });
  }
};

export const sendpurchase = (props, callback) => (dispatch) => {
  try {
    Api.get(`/api/getGameDetails?gameId=${props.id}`, {
      headers: {
        Authorization: props.accessToken,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const thisGame = doc(firestore, "games", props.id);
      getDoc(doc(thisGame, "logs", props.userid)).then((doc) => {
        if (doc.exists()) {
          callback({ exits: true });
          //doc.forEach((doc1) => allusers.push(doc1.data()));
        } else {
          callback({ exits: false });
        }
      });
      dispatch({
        type: SENDTOPURHASE,
        payload: res.data.data,
      });
    });
  } catch (err) {
    callback({ exits: false });
  }
};

export const sendtoken = (props, callback) => (dispatch) => {
  try {
    dispatch({
      type: SENDTOKEN,
      payload: props,
    });
  } catch (err) {
    callback({ exits: false });
  }
};

export const sendcampaign = (data) => (dispatch) => {
  try {
    dispatch({
      type: SENDCAMPAIGNS,
      payload: data,
    });
  } catch (err) {}
};
