import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-component-countdown-timer/lib/styles.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "./verticaltimeline.css";
import Game from "../Components/Game";

class Demogame extends React.Component {
  gameState = {
    gameTime: 60,
    gamePrice: 3000000,
    dropsPerSecond: 100,
    countdownTime: 10,
    minPrice: 1,
    userPhone: "Demo Player",
    gameId: "Demo game",
    imageUrl: require("../resources/images/demo-flat.png"),
    videoUrl: require("../resources/video/demo-flat.m4v"),
    description:
      "A beautiful  brand new studio apartment located in the heart of Orlim.\nThis wonderful flat of 695sq.ft. could be yours if you play today.",
    detailsUrl: "https://www.commonfloor.com/ratan-exotica-goa/povp-vp5zb1",
    title: "Play 2 Win: A brand new flat",
    subTitle: "",
    isFreeGame: true,
  };

  render() {
    return (
      <Container fluid className="gameBackgrund">
        <Row className="justify-content-md-center ">
          <Col lg="4">
            <Game
              gameState={this.gameState}
              gameId={"Demo game"}
              includeFeedbackButton={true}
              includePlayAgainButton={true}
            ></Game>
          </Col>

          <Col lg="6" className="mt50 mb50">
            <VerticalTimeline layout={"1-column"}>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
                }}
                contentStyle={{
                  background:
                    "linear-gradient(rgba(33, 150, 243,0.3),rgba(0, 0, 0,0.3))",
                  color: "#fff",
                }}
                position={"left"}
              >
                <p style={{ fontSize: "14px" }}>
                  <strong> On Game day login to play the Game</strong>
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                position={"left"}
                iconStyle={{
                  background: "rgb(16, 204, 82)",
                  color: "#fff",
                }}
                contentStyle={{
                  background:
                    "linear-gradient(rgba(16, 204, 82,0.3),rgba(0, 0, 0,0.3))",
                  color: "#fff",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  <strong>
                    Once Game Starts, game will run for 60 seconds
                  </strong>
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                position={"left"}
                className="vertical-timeline-element--work"
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
                }}
                contentStyle={{
                  background:
                    "linear-gradient(rgba(33, 150, 243,0.3),rgba(0, 0, 0,0.3))",
                  color: "#fff",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  <strong>
                    Price will drop from MRP to Rs 1 or a minimum Price.
                  </strong>
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                position={"left"}
                className="vertical-timeline-element--work"
                iconStyle={{
                  background: "rgb(233, 30, 99)",
                  color: "#fff",
                }}
                contentStyle={{
                  background:
                    "linear-gradient(rgba(233, 30, 99,0.3),rgba(0, 0, 0,0.3))",
                  color: "#fff",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  <strong>Select the Price you are willing to Pay.</strong>
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
                }}
                contentStyle={{
                  background:
                    "linear-gradient(rgba(33, 150, 243,0.3),rgba(0, 0, 0,0.3))",
                  color: "#fff",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  <strong>
                    At end of 60 seconds, the game is over and you can see all
                    the people who played and their respective selected Prices.
                  </strong>
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                position={"left"}
                className="vertical-timeline-element--education"
                iconStyle={{
                  background: "rgb(16, 204, 82)",
                  color: "#fff",
                }}
                contentStyle={{
                  background:
                    "linear-gradient(rgba(16, 204, 82,0.3),rgba(0, 0, 0,0.3))",
                  color: "#fff",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  <strong>
                    If you are among the winners, then you will get a popup to
                    accept the Prize at the Price you selected. If you Accept,
                    then we will contact you with further instructions.
                  </strong>
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Demogame;
