import React, { Component } from "react";
import { auth } from "../Components/Firebase";
import Api from "../Components/Api";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import Loader from "../Components/Loader";
import { withRouter } from "../Components/WithRouter";
import { connect } from "react-redux";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { css } from "@emotion/css";
import AdminGameForm from "../Components/AdminGameForm";
import AdminPrizeSubForm from "../Components/AdminPrizeSubForm";
import { debounce } from "@mui/material";

class AdminCampaign extends Component {
  state = {
    isAdmin: false,
    isLoaded: false,
    games: [],
    gameBeingEdited: null,
    gameBeingDeleted: null,
    gameIdForDeletionConfirmationText: "",
    deleteError: false,
    deleting: false,
  };

  componentDidMount() {
    if (this.props.campaign) {
      this.setState({ campaign: this.props.campaign });
    }
    auth.onAuthStateChanged((user) => {
      user?.getIdTokenResult().then((idTokenResults) => {
        // any truthy value will not do. it needs to be explicitly `true`
        const isAdmin = idTokenResults.claims.admin === true;
        this.setState({ isAdmin: isAdmin });
        if (isAdmin) {
          const config = {
            headers: {
              Authorization: user.accessToken,
              "Content-Type": "application/json",
            },
          };
          Api(`/api/campaigns/${this.props.router.params.id}`, config).then(
            (response) => {
              this.setState({ campaign: response.data });
            }
          );
          Api(
            `/api/games?campaign=${this.props.router.params.id}`,
            config
          ).then((response) => {
            if (response.status === 403) {
              this.setState({ isAdmin: false, isLoaded: true });
            } else {
              this.setState({ games: response.data, isLoaded: true });
            }
          });
        } else {
          this.setState({ isLoaded: true });
        }
      });
    });
  }

  editGame(game) {
    this.setState({ gameBeingEdited: game });
  }

  confirmGameDeletion(game) {
    this.setState({
      gameBeingDeleted: game,
      gameIdForDeletionConfirmationText: "",
    });
  }

  deleteGame(event) {
    this.setState({
      deleteError: false,
      deleting: true,
    });
    event.stopPropagation();
    event.preventDefault();
    const gameBeingDeleted = this.state.gameBeingDeleted;
    const currentUser = auth.currentUser;
    const config = {
      headers: {
        Authorization: currentUser.accessToken,
        "Content-Type": "application/json",
      },
    };
    Api.delete(`/api/games/${gameBeingDeleted.gameId}/`, config)
      .then(() => {
        const updatedGames = this.state.games.filter(
          (g) => g.gameId !== gameBeingDeleted.gameId
        );
        this.setState({
          games: updatedGames,
          gameBeingDeleted: null,
          deleting: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          deleteError: true,
          deleting: false,
        });
      });
  }

  addGame() {
    this.setState({
      gameBeingEdited: {
        buyAtPrice: this.state.campaign.isEligibilityAtCampaignLevel
          ? this.state.campaign.buyAtPrice
          : 10,
        prizes: [
          {
            images: [],
            prizeId: "",
            gift: "MAIN",
            brand: "",
            maxPrice: 100,
            minPrice: 1,
            name: "",
            count: 1,
            allowUpdate: false,
            type: "",
            description: "",
            model: "",
          },
        ],
        isCouponEnabled: false,
        options: {
          maxValue: parseInt(this.state.campaign.customUI.prizesWorth) || null,
          minValue: 1,
          duration: 60,
          gameId: "",
        },
        status: "PENDING",
        buyUntil: null,
        customUI: {
          title: this.state.campaign.customUI.title,
          promoImageURL: this.state.campaign.customUI.promoImageURL,
          brandLogoURL: this.state.campaign.customUI.brandLogoURL,
          brandName: this.state.campaign.customUI.brandName,
          primary: this.state.campaign.customUI.primary,
          secondary: this.state.campaign.customUI.secondary,
          background: this.state.campaign.customUI.background[0],
        },
        isEligibilityAtCampaignLevel:
          this.state.campaign.isEligibilityAtCampaignLevel,
        startAt: null,
        type: "DAILY",
        campaignId: this.state.campaign.campaignId,
        gameId: "",
        isFreeGame: false,
        clientId: this.state.campaign.clientId,
        worker: "playLiveGame",
      },
    });
  }

  stopEdit() {
    this.setState({ gameBeingEdited: null });
  }

  render() {
    if (!this.state.isLoaded) {
      return <Loader></Loader>;
    }
    if (!this.state.isAdmin) {
      return (
        <h1 className="h1">
          You do not have the necessary permissions to view this page.
        </h1>
      );
    }
    return (
      <Container>
        <h1 className="h1">
          <a
            href="/admin"
            className="whitetext text-decoration-none float-start"
            title="Back to Campaigns"
          >
            &larr;
          </a>
          All Games in {this.state.campaign?.title}
          <Button
            variant="success"
            style={{ float: "right", margin: "10px" }}
            onClick={() => this.addGame()}
          >
            Add Game
          </Button>
        </h1>
        <Row
          className="justify-content-evenly"
          gap={3}
          direction={"horizontal"}
        >
          {this.state.games.map((game) => (
            <Col lg={4} sm key={game.gameId}>
              <Card
                style={{
                  background: game.customUI.background,
                  padding: "1em",
                }}
              >
                <Card.Header className="d-flex justify-content-between">
                  <Button
                    onClick={() => {
                      this.editGame(game);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    className="btn-danger"
                    onClick={() => {
                      this.confirmGameDeletion(game);
                    }}
                  >
                    Delete
                  </Button>
                </Card.Header>
                <Card.Img
                  style={{ objectFit: "contain", margin: "auto" }}
                  variant="top"
                  src={game.customUI.promoImageURL}
                ></Card.Img>
                <Card.Body>
                  <Card.Title
                    className="whitetext2"
                    style={{ color: game.customUI.secondary }}
                  >
                    {game.customUI.title}
                  </Card.Title>
                  <Card.Text
                    className="whitetext2"
                    style={{
                      color: game.customUI.secondary,
                      paddingTop: "0.5rem",
                    }}
                  >
                    Gameday:{" "}
                    {moment(game.startAt).format("DD MMMM YYYYY h:mm a")}
                  </Card.Text>
                  <NavLink
                    className={
                      "bouncy1 btn Button2 " +
                      css({
                        background: game.customUI.primary,
                        color: game.customUI.secondary,
                        "&:hover": {
                          background: game.customUI.secondary,
                          color: game.customUI.primary,
                        },
                      })
                    }
                    to={`/game/${game.gameId}`}
                  >
                    Goto Game Page
                  </NavLink>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {!this.state.games.length && (
            <Col lg={4} sm>
              <Card className="cardcampaign">
                <Card.Header>
                  <h3>No Games Added</h3>
                </Card.Header>
                <Card.Footer>
                  <Button variant="success" onClick={() => this.addGame()}>
                    Add Game
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          )}
        </Row>
        {this.state.gameBeingEdited && (
          <Modal
            show={this.state.gameBeingEdited}
            onHide={() => this.stopEdit()}
            backdrop="static"
            keyboard={false}
            dialogClassName="max-width-615"
          >
            <Modal.Header closeButton>
              {this.state.gameBeingEdited.gameId
                ? `Edit ${this.state.gameBeingEdited.gameId}`
                : "Create New Game"}
            </Modal.Header>
            <Modal.Body>
              <AdminGameForm
                game={this.state.gameBeingEdited}
                onSubmit={(game) => this.updateGame(game)}
              />
            </Modal.Body>
          </Modal>
        )}
        {this.state.gameBeingDeleted && (
          <Modal
            show={this.state.gameBeingDeleted}
            onHide={() => this.confirmGameDeletion(null)}
            backdrop="static"
            keyboard={false}
            dialogClassName="max-width-615"
          >
            <Modal.Header closeButton>
              {`Delete ${this.state.gameBeingDeleted.customUI.title}?`}
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>This action is irreversible.</strong> All records of
                this game shall be deleted, including the logs, participants and
                payment details.
              </p>
              <p>
                <em>
                  Note: Any uploaded images (prizes/promo) will not be deleted
                  as other games can reuse them.
                </em>
              </p>
              <p>
                Are you <strong>sure</strong> you want to do this?
              </p>

              <Form
                noValidate
                style={{ display: "flex", flexDirection: "column" }}
                onSubmit={(e) => this.deleteGame(e)}
              >
                <Form.Group controlId="gameId">
                  <Form.Label>
                    <p>
                      To confirm you want to delete this game, please type&nbsp;
                      <code>{this.state.gameBeingDeleted.gameId}</code> in the
                      box below.
                    </p>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        gameIdForDeletionConfirmationText: e.target.value,
                      });
                    }}
                    required
                    autoComplete="off"
                    placeholder={this.state.gameBeingDeleted.gameId}
                  />
                </Form.Group>
                {this.state.deleteError && (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: ".875em",
                      color: "#dc3545",
                    }}
                  >
                    Failed to delete game. Please try again.
                  </div>
                )}
                <Button
                  className="mt-2"
                  variant={
                    this.state.gameIdForDeletionConfirmationText ===
                    this.state.gameBeingDeleted?.gameId
                      ? "danger"
                      : "secondary"
                  }
                  type="submit"
                  disabled={
                    this.state.gameIdForDeletionConfirmationText !==
                    this.state.gameBeingDeleted?.gameId
                  }
                >
                  Delete Game
                </Button>
              </Form>
              {this.state.deleting && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "-5rem",
                    left: "-2rem",
                    zIndex: 10,
                    width: "calc(100% + 4rem)",
                    height: "calc(100% + 8rem)",
                    background: "#000000B8",
                    color: "#FFFFFF",
                  }}
                >
                  <h3>Deleting</h3>
                  <p>This should take a few seconds</p>
                </div>
              )}
            </Modal.Body>
          </Modal>
        )}
      </Container>
    );
  }

  updateGame(game) {
    let matchFound = false;
    const updatedGames = this.state.games.map((c) => {
      if (c.gameId === game.gameId) {
        matchFound = true;
        return game;
      } else {
        return c;
      }
    });
    if (!matchFound) {
      updatedGames.push(game);
    }
    this.setState({ games: updatedGames });
    this.stopEdit();
  }
}

const mapStateToProps = (state) => ({
  upcoming: state.authReducer.upcoming,
  eligible: state.authReducer.eligible,
});

export default connect(mapStateToProps)(withRouter(AdminCampaign));
