import React, { useEffect, useState } from "react";
import { auth, firestore } from "../Components/Firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import { storeuser } from "../Store/action/auth";
import { connect } from "react-redux";
import Loader from "../Components/Loader";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Button from "@mui/material/Button";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import "react-phone-input-2/lib/style.css";

const Login = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [postVerificationResponse, setPostVerificationResponse] =
    useState(null);
  useEffect(readOtpFromSMS, []);
  const redirectTo = location.state?.from?.pathname || "/";

  function readOtpFromSMS() {
    if (navigator.credentials) {
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          if (otp) {
            setOtp(otp.code);
            ac.abort();
          }
        })
        .catch((err) => {
          ac.abort();
          console.log("error reading OTP:", err);
        });
    }
  }

  function updateUserProfile(result) {
    const users = collection(firestore, "users");
    const userId = result.user.uid;
    const q = query(users, where("userId", "==", userId));
    getDocs(q)
      .then((docs) => {
        if (!docs.empty) {
          docs.forEach((doc1) => props.storeuser(doc1.data()));
          setIsSubmitting(false);
          navigate(redirectTo);
        } else {
          setDoc(doc(firestore, "users", userId), {
            userId: userId,
            email: result.user.email,
            name: result.user.displayName,
            phoneNumber: result.user.phoneNumber,
          }).then((res) => {
            props.storeuser({
              userId: userId,
              email: result.user.email,
              name: result.user.displayName,
              phoneNumber: result.user.phoneNumber,
            });
            setIsSubmitting(false);
            navigate(redirectTo);
          });
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        setLoader(false);
        setErrorMessage(
          "Unable to login. Check your internet connection and try again."
        );
        console.log("Set User Error:", error);
      });
  }

  const getCleanPhoneNumber = () => {
    if (!phoneNumber) {
      return phoneNumber;
    }
    let finalNumber = phoneNumber;
    if (finalNumber.length === 10) {
      finalNumber = `91${finalNumber}`;
    } else if (finalNumber.length === 11 && finalNumber[0] === "0") {
      finalNumber = `91${finalNumber.substring(1)}`;
    }
    if (!finalNumber.startsWith("+")) {
      finalNumber = `+${finalNumber}`;
    }
    return finalNumber;
  };

  const signInWithPhone = () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const finalNumber = getCleanPhoneNumber();
    let error = "";
    if (!finalNumber) {
      error = "Please enter your phone number";
    } else if (finalNumber.length < 13) {
      error = "Please enter a valid phone number";
    }

    if (error) {
      setErrorMessage(error);
      document.getElementById("phoneNumber").focus();
      setIsSubmitting(false);
      return;
    }

    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // necessary empty callback
          },
        },
        auth
      );
    } catch (e) {
      //recaptcha already verified
      setIsSubmitting(false);
      console.error("Recaptcha error", e);
    }
    signInWithPhoneNumber(auth, finalNumber, window.recaptchaVerifier)
      .then((result) => {
        setPostVerificationResponse(result);
        setShowOtpScreen(true);
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Sign in error", err);
        setErrorMessage("Please enter a valid phone number");
        setIsSubmitting(false);
      });
  };

  const validateOtp = () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    if (otp.length !== 6 || postVerificationResponse === null) return;
    postVerificationResponse
      .confirm(otp)
      .then((result) => {
        setLoader(true);
        updateUserProfile(result);
      })
      .catch((err) => {
        if (loader) {
          // this means the OTP validation was successful but something went
          // wrong within updateUserProfile.
          // Since we have seen this happen only for 1 existing user and never on
          // new user registration, we assume it is fine to skip that and just
          // redirect to the next page.
          console.error("updateUserProfile error", err);
          navigate(redirectTo);
        } else {
          console.error("OTP verification error", err);
          setErrorMessage("Wrong OTP");
          setIsSubmitting(false);
        }
      });
  };

  return (
    <Container fluid className="gameBackgrund justify-content-md-center">
      {!loader && <Loader />}
      {loader && (
        <Row className="justify-content-md-center  mb-5 ">
          <Col lg="4" className="mt50">
            <Card className="text-center  cont">
              <Card.Body className="shadowwithcircle">
                <Card.Title>
                  <p className="h1 mt20 mb50">Welcome To Play2Win</p>
                </Card.Title>
                <div className="countdown mb50">
                  {showOtpScreen ? (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validateOtp();
                      }}
                    >
                      <OtpInput
                        numInputs={6}
                        value={otp}
                        inputStyle={{
                          height: "2em",
                          width: "2em",
                          borderRadius: "5px",
                          fontSize: "20px",
                        }}
                        errorStyle={{ borderColor: "red", color: "red" }}
                        onChange={(value) => {
                          setErrorMessage("");
                          setOtp(value);
                        }}
                        shouldAutoFocus={true}
                        isInputNum={true}
                        hasErrored={!!errorMessage}
                        separator={<span>--</span>}
                      />
                      <p style={{ color: "red" }}>{errorMessage}</p>
                      <Button
                        variant="contained"
                        type="submit"
                        className="Button2"
                        disabled={isSubmitting}
                      >
                        Validate
                      </Button>
                      <Button onClick={() => setShowOtpScreen(false)}>
                        <small>Did not receive OTP?</small>
                      </Button>
                    </form>
                  ) : (
                    <div>
                      <PhoneInput
                        enableSearch={true}
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        country="in"
                        onChange={(value) => {
                          setErrorMessage("");
                          setPhoneNumber(value);
                        }}
                        onBlur={() => {
                          setPhoneNumber(getCleanPhoneNumber());
                        }}
                        onEnterKeyPress={(e) => {
                          e.stopPropagation();
                          signInWithPhone();
                        }}
                        inputProps={{
                          autoFocus: true,
                          id: "phoneNumber",
                          autoComplete: "tel",
                        }}
                      />
                      <p style={{ color: "red" }}>{errorMessage}</p>
                      <div id="recaptcha-container">__</div>
                      <Button
                        className="Button2"
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={signInWithPhone}
                      >
                        Sign In
                      </Button>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  storeuser: (data) => dispatch(storeuser(data)),
});

export default connect(null, mapDispatchToProps)(Login);
