import { Component } from "react";
import { auth, askForPermissioToReceiveNotifications } from "./Firebase";
import Api from "./Api";
import { connect } from "react-redux";
import { sendtoken } from "../Store/action/auth";

class Notification extends Component {
  componentDidMount() {
    let { sendtoken } = this.props || {};
    askForPermissioToReceiveNotifications().then((response) => {
      if (!this.props.sendvalid) {
        const currentUser = auth.currentUser;
        Api.post(
          "/api/storeFCMToken",
          {
            tokenId: response,
            userId: currentUser.uid,
          },
          {
            headers: {
              Authorization: currentUser.accessToken,
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            sendtoken(true);
            //  console.log(res);
          })
          .catch((err) => {});
      }
    });
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  sendvalid: state.authReducer.sendvalid,
});
const mapDispatchToProps = (dispatch) => ({
  sendtoken: (data, callback) => dispatch(sendtoken(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
