import React from "react";
import { Row, Col } from "react-bootstrap";
import Clock from "react-live-clock";
import moment from "moment";

const RealtimeClock = (props) => {
  let { customUI } = props || {};
  const time = moment.unix(props.time).toISOString(true);
  return (
    <Row
      className="justify-content-md-center whitetext"
      style={{
        paddingTop: 5,
        margin: "auto",
        display: "table",
        fontSize: 28,
        color: customUI && customUI.secondary,
      }}
    >
      <Col>
        <Clock
          date={time}
          timezone={"Asia/Kolkata"}
          format={"MMM DD, YYYY, h:mm:ss A"}
          ticking={true}
          style={{ fontSize: "max(28px, 6.75vw)" }}
        />
      </Col>
    </Row>
  );
};

export default RealtimeClock;
