import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { firestore } from "../Components/Firebase";

const AboutUs = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      const user = firestore.collection("contact");
      var newuser = user.doc();
      newuser
        .set({
          contactid: newuser.id,
          firstname: fname,
          lastname: lname,
          email: email,
          message: message,
          CreatedOn: Date(),
        })
        .then((res) => {
          handleShow();
        });
    }
  };

  return (
    <Container fluid className="container1 gameBackgrund">
      <Row className="justify-content-md-center mb50">
        <Col lg="10" className="aboutContent mt20">
          <div className="header">
            <h1 className="headerText">Us</h1>
          </div>
          <div id="aboutData">
            <p>
              Play2Win is a plug&play web-app, where participants can Play2Win
              discounts on listed products for upto 99.9%* off in 60 seconds by
              your choice.
            </p>
            <p>
              We gamify bargain shopping to help retailers sell discounted &
              end-of-season inventory. A nifty way to get people to buy unsold
              products in a adrenaline fueled frenzy of competition. This fits
              in perfectly in the vast number of Discount Shopping markets of
              India of flash sales & daily deals. Play2Win is a{" "}
              <b>win-win-win</b> situation for the consumer, the producer & the
              seller, with high savings & high margins.
            </p>
            <p>
              <b>D-D-D:</b>&nbsp;&nbsp;Disruptive - Deep - Discount is a non
              predatory discount innovation/method, which disrupts the discount
              monopoly of giant companies & where customers are in control of
              discounts in a very playful, engaging experience & receive rewards
              & assured assorted prizes for every click.
            </p>
            <p>
              Life is a game, and no game is complete without a reward.{" "}
              <b>Savings is Winnings</b>
              <br />
              Life is like a box of chocolates, risks and rewards.
              <br />
              "To win big, you must take big risks" - Bill Gates
            </p>

            <p style={{ fontSize: "0.8rem" }}>
              Refer to our
              <b>
                <a
                  style={{ marginLeft: 5 }}
                  className="inlineLink"
                  href="/termsandcondition"
                >
                  Terms & Conditons ,{" "}
                </a>
              </b>
              <b>
                <a
                  style={{ marginLeft: 5, marginRight: 5 }}
                  className="inlineLink"
                  href="/privacypolicy"
                >
                  {" "}
                  Privacy Policy
                </a>
              </b>
              and
              <b>
                <a
                  style={{ marginLeft: 5 }}
                  className="inlineLink"
                  href="/refundpolicy"
                >
                  {" "}
                  Return & Refund Policy
                </a>
              </b>
            </p>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb50">
        <Col lg="6" className="teamContent">
          <div className="header">
            <h1 className="headerText">Team</h1>
          </div>
          <Row className="teamLayout">
            <Col lg="6" xs="12" id="teamCEO">
              <img
                className="teamPic"
                // style={{ borderRadius: "50%" }}
                src={require("../resources/images/team/teamOzzy.png")}
                alt="profile"
              />
              <h2>Oz Furtado</h2>
              <h3>CEO</h3>
            </Col>
            {/* <div className="horBar"></div>
							<Col lg="5" xs="12" id="teamMembers">
              
								<div className="teamMemRow">
                <img  
                className="teamPic"
                // style={{ width: "30%", borderRadius: "50%" }}
                          src={require("../resources/images/team/teamZaneAlt01.jpg")}
                          alt="profile"
                        />
									<div className="teamDetails">
										<h2>Zane Christain Vaz</h2>
										<h6>CTO <br className="mobileOnly"/><span className="laptopOnly">|</span> <a href="mailto:zaney.vaz@gmail.com?Subject=Hi%20Zane!" target="_top" className="inlineLinkAlt">zaney.vaz@gmail.com</a></h6>
									</div>
								</div>
					 
							</Col> */}
          </Row>
        </Col>
        <Col lg="4" className="formContent">
          <div className="header">
            <h1 className="headerText">Contact!</h1>
          </div>
          <p>Feel free to contact us for anything.</p>

          <Form noValidate validated={validated} onSubmit={onSendmsg}>
            <Form.Group controlId="validationCustom01">
              <Form.Control
                required
                type="text"
                placeholder="First Name"
                className="inputField"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter First Name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Control
                required
                type="text"
                placeholder="Last Name"
                className="inputField"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Last Name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustomEmail">
              <Form.Control
                required
                type="email"
                placeholder="Email Address"
                className="inputField"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Email
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustomMessage">
              <Form.Control
                required
                as="textarea"
                rows="3"
                className="textAreaField"
                placeholder="Enter Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter message
              </Form.Control.Feedback>
            </Form.Group>
            {/* <label>{err}</label> */}
            <div className="contactForm">
              <Button
                variant="primary"
                className="formButton"
                type="submit"
                // onClick={onSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>

          <Row>
            <Col lg="6">
              <p className="CFLeft">
                Address:<br></br>Bidtronix Pvt Ltd,<br></br>FiiRE, Don Bosco,
                <br></br>Fatorda, Margao, Goa<br></br>403602
              </p>
            </Col>

            <Col lg="6">
              <p className="CFRight">
                Tel:<br></br>
                <a href="tel:+917507670077" className="inlineLink">
                  +91 750767-0077
                </a>
                <br></br>
                <br></br>E-mail:<br></br>
                <a
                  href="mailto:team@playtowin.win?Subject=Play2Win%20Query"
                  target="_top"
                  className="inlineLink"
                >
                  team@playtowin.win
                </a>
              </p>
            </Col>
          </Row>
          <p style={{ fontSize: "0.8rem" }}>
            &copy; Play2Win, Patent Pending Technology
          </p>
        </Col>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>Message successfully submitted!!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                setFname("");
                setLname("");
                setEmail("");
                setMessage("");
                setValidated(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </Container>
  );
};

export default AboutUs;
