import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import BeautyStars from "beauty-stars";
import { auth, firestore } from "./Firebase";

export default class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = { show: true };

    this.onClose = this.props.onClose ? this.props.onClose : () => {};
  }

  sendFeedbackMessage = (event) => {
    const currentUser = auth.currentUser;
    const userdoc = firestore.collection("feedback");
    const newuser = userdoc.doc();
    newuser
      .set({
        feedbackid: newuser.id,
        userId: currentUser.uid,
        message: this.state.message,
        gameId: this.props.gameId,
        Rating: this.state.value,
        userName: currentUser.displayName,
        CreatedOn: Date(),
      })
      .then((res) => {});
    this.setState({ show: false });
  };
  render() {
    return (
      <>
        <Modal
          show={this.state.show}
          onHide={this.onClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>We Value your Opinion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginBottom: 30 }}>
              <BeautyStars
                activeColor="#4F56F9"
                size="25px"
                value={this.state.value}
                onChange={(value) =>
                  this.setState({
                    value,
                  })
                }
              />
            </div>

            <Form>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Enter Your Message"
                value={this.state.message}
                onChange={(e) => {
                  this.setState({
                    message: e.target.value,
                  });
                }}
              />
            </Form>
            <Button
              variant="primary"
              className="mt20"
              type="submit"
              onClick={() => this.sendFeedbackMessage()}
            >
              Submit
            </Button>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }
}
